import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Row,
  Col,
  Popover,
  Input,
  PopoverBody,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import logo from "./images/logo.png";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { RiCloseCircleLine, RiAdminFill } from "react-icons/ri";
import { AiOutlineUserAdd } from "react-icons/ai";
import { connect } from "react-redux";

function NavBar(props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logInMessage, setLogInMessage] = useState([]);
  const [logInAccepted, setLogInAccepted] = useState(false);
  const [userGenre, setUserGenre] = useState("");
  const [usersName, setUsersName] = useState("");
  const [userFoundFromToken, setUserFoundFromToken] = useState(
    localStorage.getItem("usersToken")
  );
  const [userType, setUserType] = useState("");
  const [popoverOpenMenu, setPopoverOpenMenu] = useState(false);

  const toggleMenu = () => setPopoverOpenMenu(!popoverOpenMenu);

  useEffect(async () => {
    if (userFoundFromToken != null) {
      var rawData = await fetch("/sendToken", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `tokenFromFront=${userFoundFromToken}`,
      });

      var data = await rawData.json();

      if (data.result === true) {
        setLogInAccepted(true);
        setUsersName(data.user.prenom);
        setUserGenre(data.user.genre);
        setUserType(data.user.type);
      }
    }
  }, []);

  const toggle = () => setPopoverOpen(!popoverOpen);

  var handleLogout = () => {
    localStorage.removeItem("usersToken");
    setLogInAccepted(false);
  };

  var handleSignIn = async () => {
    var rawData = await fetch("/signIn", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `emailFromFront=${email}&passwordFromFront=${password}`,
    });

    var data = await rawData.json();
    if (data.result == true) {
      setLogInAccepted(true);
      setUsersName(data.usersName);
      setUserType(data.userType);
      localStorage.setItem("usersToken", data.token);
      // window.location.reload()
    } else {
      setLogInMessage(data.errors);
    }
  };

  var adminButton;

  if (userType === "admin") {
    adminButton = (
      <Button
        size="xl"
        style={{
          width: "28vh",
          marginBottom: "1vh",
          backgroundColor: "#206A37",
        }}
      >
        <Link to="/adminHomePage" style={{ color: "white" }}>
          <RiAdminFill style={{ marginRight: 5 }} />
          Page Administrateur
        </Link>
      </Button>
    );
  }

  var userBoard = (
    <Popover
      placement="bottom"
      isOpen={popoverOpen}
      target="Popover1"
      toggle={toggle}
    >
      <PopoverBody
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ padding: "1vh", color: "#206A37", fontWeight: "bold" }}>
          Se connecter
        </span>
        <Input
          type="email"
          placeholder="Email"
          style={{ marginBottom: "1vh", width: "auto" }}
          onChange={(e) => setEmail(e.target.value)}
        ></Input>
        <Input
          type="password"
          placeholder="Password"
          style={{ marginBottom: "1vh", width: "auto" }}
          onChange={(e) => setPassword(e.target.value)}
        ></Input>
        <span style={{ padding: "1vh", color: "red", fontWeight: "bold" }}>
          {logInMessage}
        </span>
        <Button
          style={{
            width: "28vh",
            marginBottom: "1vh",
            backgroundColor: "#206A37",
          }}
          onClick={() => handleSignIn()}
        >
          Confirmer
        </Button>
        <Button style={{ width: "28vh", backgroundColor: "#206A37" }}>
          <Link to="/creationdecompte" style={{ color: "white" }}>
            Créer un compte
          </Link>
        </Button>
      </PopoverBody>
    </Popover>
  );

  var userIcon = (
    <AiOutlineUserAdd size="2x" style={{ width: "40px", color: "#206A37" }} />
  );

  if (logInAccepted === true) {
    var firstLetter = usersName.charAt(0);

    userIcon = (
      <span
        style={{
          fontSize: 15,
          color: "#206A37",
          fontWeight: "bold",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaUserCircle
          size="2x"
          style={{ width: "40px", color: "#206A37", marginBottom: 5 }}
        />
        {usersName}
      </span>
    );

    userBoard = (
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover1"
        toggle={toggle}
      >
        <PopoverBody
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{ padding: "1vh", color: "#206A37", fontWeight: "bold" }}
          >
            Bienvenue {userGenre} {usersName} !
          </span>
          {adminButton}
          <Button
            size="sm"
            style={{
              width: "28vh",
              marginBottom: "1vh",
              backgroundColor: "#206A37",
            }}
          >
            <Link to="/wishlist" style={{ color: "white" }}>
              Voir mes favoris
            </Link>
          </Button>
          <Button
            size="sm"
            style={{
              width: "28vh",
              marginBottom: "1vh",
              backgroundColor: "#206A37",
            }}
          >
            <Link to="/mesrecherches" style={{ color: "white" }}>
              Voir mes dernières recherches
            </Link>
          </Button>
          <Button
            size="sm"
            style={{ width: "28vh", backgroundColor: "#206A37" }}
            onClick={() => handleLogout()}
          >
            Se déconnecter
          </Button>
        </PopoverBody>
      </Popover>
    );
  }

  var burguerMenu = (
    <Popover
      placement="bottom-end"
      hideArrow={true}
      isOpen={popoverOpenMenu}
      target="Popover2"
      toggle={toggleMenu}
      style={{ width: 250 }}
    >
      <ListGroup>
        <ListGroupItem
          style={{
            fontWeight: "bold",
            color: "gray",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontSize: 25 }}>MENU :</span>
          <RiCloseCircleLine
            style={{ width: 20, height: 20, cursor: "pointer" }}
            onClick={() => toggleMenu()}
          />
        </ListGroupItem>
        <ListGroupItem
          disabled
          tag="button"
          action
          style={{ fontWeight: "bold", color: "#206A37" }}
        >
          GESTION LOCATIVE :
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/recherche"
            onClick={() => onClickTransaction("Louer")}
            style={{ color: "gray" }}
          >
            &nbsp;Louer
          </Link>
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/estimer"
            onClick={() =>
              props.onContactClick("Je veux mettre mon bien en location.")
            }
            style={{ color: "gray" }}
          >
            &nbsp;Mettre en location
          </Link>
        </ListGroupItem>
        <ListGroupItem
          disabled
          tag="button"
          action
          style={{ fontWeight: "bold", color: "#206A37" }}
        >
          TRANSACTION :
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/recherche"
            onClick={() => onClickTransaction("Acheter")}
            style={{ color: "gray" }}
          >
            &nbsp;Acheter
          </Link>
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/estimer"
            onClick={() => props.onContactClick("Je veux vendre mon bien.")}
            style={{ color: "gray" }}
          >
            &nbsp;Vendre mon bien
          </Link>
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/estimer"
            onClick={() => props.onContactClick("Je veux estimer mon bien.")}
            style={{ color: "gray" }}
          >
            &nbsp;Estimer mon bien
          </Link>
        </ListGroupItem>
        <ListGroupItem
          disabled
          tag="button"
          action
          style={{ fontWeight: "bold", color: "#206A37" }}
        >
          SYNDIC :
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/syndic"
            onClick={() => props.onContactClick("Je veux changer de Syndic.")}
            style={{ color: "gray" }}
          >
            &nbsp;Changer de Syndic
          </Link>
        </ListGroupItem>
        <ListGroupItem
          disabled
          tag="button"
          action
          style={{ fontWeight: "bold", color: "#206A37" }}
        >
          AGENCE :
        </ListGroupItem>
        <ListGroupItem tag="button" action>
          <Link
            to="/honoraires"
            onClick={() => props.onContactClick("Je veux changer de Syndic.")}
            style={{ color: "gray" }}
          >
            &nbsp;Honoraires de l'agence
          </Link>
        </ListGroupItem>
      </ListGroup>
    </Popover>
  );

  function changeWeight(e) {
    e.target.style.fontWeight = "bolder";
  }

  function changeBackWeight(e) {
    e.target.style.fontWeight = "normal";
  }

  function changeBorder(e) {
    e.target.style.border = "1px solid #206A37";
  }

  function changeBackBorder(e) {
    e.target.style.border = "none";
  }

  function onClickTransaction(type) {
    localStorage.setItem("transaction", type)
    props.onTypeClick(type);
    props.onBoundsValider([]);
  }

  return (
    <Row style={navBarRow}>
      <Col
        xs="12"
        lg="1"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <span
          id="Popover2"
          type="button"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 25,
          }}
        >
          <FiMenu style={{ height: 40, width: 40, color: "#206A37" }} />
          <span style={{ color: "#206A37" }}>MENU</span>
        </span>
        {burguerMenu}
      </Col>

      <Col
        xs="12"
        lg="1"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} style={{ width: 120 }} />
        </Link>
      </Col>

      <Col
        xs="12"
        lg="2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#206A37",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/quisommesnous"
            style={{ color: "#206A37", textAlign: "center" }}
            onMouseOver={changeWeight}
            onMouseOut={changeBackWeight}
          >
            QUI SOMMES NOUS
          </Link>
        </span>
      </Col>
      <Col
        xs="12"
        lg="2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#206A37",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/nosagences"
            style={{ color: "#206A37", textAlign: "center" }}
            onMouseOver={changeWeight}
            onMouseOut={changeBackWeight}
          >
            NOS AGENCES
          </Link>
        </span>
      </Col>
      <Col
        xs="12"
        lg="2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#206A37",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/biensvendus"
            style={{ color: "#206A37", textAlign: "center" }}
            onMouseOver={changeWeight}
            onMouseOut={changeBackWeight}
          >
            BIENS VENDUS
          </Link>
        </span>
      </Col>
      <Col
        xs="12"
        lg="1"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#206A37",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/outils"
            style={{ color: "#206A37" }}
            onMouseOver={changeWeight}
            onMouseOut={changeBackWeight}
          >
            OUTILS
          </Link>
        </span>
      </Col>
      <Col
        xs="12"
        lg="2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#206A37",
            fontSize: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to="/contact"
            style={{ color: "#206A37" }}
            onMouseOver={changeWeight}
            onMouseOut={changeBackWeight}
          >
            CONTACT
          </Link>
        </span>
      </Col>
      <Col
        xs="12"
        lg="1"
        style={{ display: "flex", justifyContent: "center", fontSize: "1vw" }}
      >
        <Button
          id="Popover1"
          style={{
            backgroundColor: "transparent",
            border: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          {userIcon}
        </Button>
        {userBoard}
      </Col>
    </Row>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onContactClick: function (reason) {
      dispatch({ type: "addReason", whatReason: reason });
    },
    onTypeClick: function (type) {
      dispatch({ type: "addType", whatType: type });
    },
    onBoundsValider: function (bounds) {
      dispatch({ type: "setLatAndLng", whatBounds: bounds });
    },
  };
}

var navBarRow = {
  backgroundColor: "white",
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "row",
  justifySelf: "flex-start",
  alignSelf: "center",
  alignItems: "center",
  justifyContent: "center",
  padding: "1vw",
  marginBottom: 50,
};

export default connect(null, mapDispatchToProps)(NavBar);
