import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, PopoverBody, Button, Spinner, ButtonGroup } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import PictureCarousel from './carosel'
import { BiArrowBack } from 'react-icons/bi';
import { IoMdHeartEmpty } from 'react-icons/io';
import { Table } from 'reactstrap';
import emailjs from 'emailjs-com';
import Footer from './Footer'
import { motion } from 'framer-motion'
import { GiConfirmed } from 'react-icons/gi';
import { AiFillWarning } from 'react-icons/ai';
import {connect} from "react-redux"
import NavBar from "./NavBar"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";





function PageBien({location}) {

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [idFromUrl, setIdFromUrl] = useState(new URLSearchParams(window.location.hash.substr(6)).get("id"))


  const [buttonClicked, setButtonClicked] = useState('Général')
  const [bienAVendre, setBienAVendre] = useState(null);
  const [heart, setHeart] = useState(false)
  const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))

  const [nomClient, setNomClient] = useState('')
  const [prenomClient, setPrenomClient] = useState('')
  const [telClient, setTelClient] = useState('')
  const [messageResult, setMessageResult] = useState('')
  const [sendClicked, setSendClicked] = useState(false)
  const [bienIncludedInFavoris, setBienIncludedInFavoris] = useState(false)
  const [typeDeBien, setTypeDeBien] = useState("")
  const [nombresDeChambresState, setNombresDeChambresState] = useState()
  const [nombreDePiecesState, setNombreDePiecesState] = useState()
  const [surfaceState, setSurfaceState] = useState()
  const [prixDuBien, setPrixDuBien] = useState()
  const [isLocation, setIsLocation] = useState(false)
  const [isVente, setIsVente] = useState(false)
  // const bienFromRedux = props.bienToDisplay
  // const [bienFromRedux, setBienFromRedux] = useState()

  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('')
  const [bienFromId, setBienFromId] = useState()

  const [bienCommentaires, setBienCommentaires] = useState()
  const [bienVille, setBienVille] = useState()
  const [bienCodePostal, setBienCodePostal] = useState()

  const [etages, setEtages] = useState()
  const [chambres, setChambres] = useState()
  const [pieces, setPieces] = useState()
  const [surfaceHabitable, setSurfaceHabitable] = useState()
  const [surfaceTerrain, setSurfaceTerrain] = useState()
  const [salleDeBain, setSalleDeBain] = useState()
  const [wc, setWc] = useState()
  const [garage, setGarage] = useState()
  const [anneeDeConstruction, setAnneeDeConstruction] = useState()

  const [pays, setPays] = useState()
  const [codePostal, setCodePostal] = useState()
  const [ville, setVille] = useState()

  const [piecesToDisplay, setPiecesToDisplay] = useState()

  const [bienEnCopro, setBienEnCopro] = useState()
  const [nombreLots, setNombreLots] = useState()
  const [lotsHabitation, setLotsHabitation] = useState()
  const [chargesAnnuelles, setChargesAnnuelles] = useState()
  const [procedures, setProcedures] = useState()



  var qs = require('qs');


  useEffect(async() => {

    var lien = window.location.hash.substr(6)
    var id = new URLSearchParams(lien).get("id")

      var bienFromIdInUseEffect;

      var getBien = await fetch("/getBienFromId", {
        method: 'POST',
        headers: {'Content-Type':'application/x-www-form-urlencoded', 'Origin': '*'},
        body: `idFromFront=${idFromUrl}`
       })
       .then(response => response.json())
       .then(obj => {
        setBienFromId(obj.thisBien)
        bienFromIdInUseEffect = obj.thisBien
      })
      
    
      setBienCommentaires(bienFromIdInUseEffect.COMMENTAIRES.FR)
      setBienCodePostal(bienFromIdInUseEffect.LOCALISATION.CODE_POSTAL)
      setBienVille(bienFromIdInUseEffect.LOCALISATION.VILLE)
      

      var keys = Object.keys(bienFromIdInUseEffect)
      var includesVente = keys.includes("VENTE")
      if(includesVente === true){
        setIsVente(true)
        setPrixDuBien(numberWithSpaces(bienFromIdInUseEffect.VENTE.PRIX))
      }


      var includesLocation = keys.includes("LOCATION")
      if(includesLocation === true){
        setIsLocation(true)
        setPrixDuBien(numberWithSpaces(bienFromIdInUseEffect.LOCATION.LOYER))
      }

      var includesMaison = keys.includes("MAISON")
      if(includesMaison === true){
        setTypeDeBien("Maison")
        setNombresDeChambresState(`${bienFromIdInUseEffect.MAISON.NBRE_CHAMBRES} ch  - `)
        setNombreDePiecesState(`${bienFromIdInUseEffect.MAISON.NBRE_PIECES} p  - `)
        setSurfaceState(`${bienFromIdInUseEffect.MAISON.SURFACE_HABITABLE} m²`)

        if(bienFromIdInUseEffect.MAISON.NBRE_NIVEAUX > 0){
          setEtages(
          <tr>
            <th scope="row">Nombre d'étages: </th>
            <td>{bienFromIdInUseEffect.MAISON.NBRE_NIVEAUX}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.MAISON.NBRE_PIECES){
          setPieces(
          <tr>
            <th scope="row">Nombre de pièces: </th>
            <td>{bienFromIdInUseEffect.MAISON.NBRE_PIECES}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.MAISON.NBRE_CHAMBRES){
          setChambres(
          <tr>
            <th scope="row">Nombre de chambres: </th>
            <td>{bienFromIdInUseEffect.MAISON.NBRE_CHAMBRES}</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.MAISON.SURFACE_HABITABLE){
          setSurfaceHabitable(
          <tr>
            <th scope="row">Surface habitable: </th>
            <td>{bienFromIdInUseEffect.MAISON.SURFACE_HABITABLE} m²</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.MAISON.SURFACE_TERRAIN > 0){
          setSurfaceTerrain(
          <tr>
            <th scope="row">Surface du terrain: </th>
            <td>{bienFromIdInUseEffect.MAISON.SURFACE_TERRAIN} m²</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.MAISON.NBRE_SALLE_BAIN){
          setSalleDeBain(
          <tr>
            <th scope="row">Nombre de salles de bain: </th>
            <td>{bienFromIdInUseEffect.MAISON.NBRE_SALLE_BAIN}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.MAISON.NBRE_WC){
          setWc(
          <tr>
            <th scope="row">Nombre de WC: </th>
            <td>{bienFromIdInUseEffect.MAISON.NBRE_WC}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.MAISON.SURFACE_GARAGE){
          setGarage(
          <tr>
            <th scope="row">Surface garage: </th>
            <td>{bienFromIdInUseEffect.MAISON.SURFACE_GARAGE} m²</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.MAISON.ANNEE_CONSTRUCTION){
          setAnneeDeConstruction(
          <tr>
            <th scope="row">Année construction: </th>
            <td>{bienFromIdInUseEffect.MAISON.ANNEE_CONSTRUCTION}</td>
          </tr>
          ) 
        }
      }


      var includesApp = keys.includes("APPARTEMENT")
      if(includesApp === true){
        setTypeDeBien("Appartement")
        if(bienFromIdInUseEffect.APPARTEMENT.CATEGORIE === "Loft"){
          setTypeDeBien("Loft")
        }
        setNombresDeChambresState(`${bienFromIdInUseEffect.APPARTEMENT.NBRE_CHAMBRES} ch  - `)
        setNombreDePiecesState(`${bienFromIdInUseEffect.APPARTEMENT.NBRE_PIECES} p  - `)
        setSurfaceState(`${bienFromIdInUseEffect.APPARTEMENT.SURFACE_HABITABLE} m²`)

        if(bienFromIdInUseEffect.APPARTEMENT.NBRE_NIVEAUX > 0){
          setEtages(
          <tr>
            <th scope="row">Nombre d'étages: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.NBRE_NIVEAUX}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.APPARTEMENT.NBRE_PIECES){
          setPieces(
          <tr>
            <th scope="row">Nombre de pièces: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.NBRE_PIECES}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.APPARTEMENT.NBRE_CHAMBRES){
          setChambres(
          <tr>
            <th scope="row">Nombre de chambres: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.NBRE_CHAMBRES}</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.APPARTEMENT.SURFACE_HABITABLE){
          setSurfaceHabitable(
          <tr>
            <th scope="row">Surface habitable: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.SURFACE_HABITABLE} m²</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.APPARTEMENT.SURFACE_TERRAIN > 0){
          setSurfaceTerrain(
          <tr>
            <th scope="row">Surface du terrain: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.SURFACE_TERRAIN} m²</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.APPARTEMENT.NBRE_SALLE_BAIN){
          setSalleDeBain(
          <tr>
            <th scope="row">Nombre de salles de bain: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.NBRE_SALLE_BAIN}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.APPARTEMENT.NBRE_WC){
          setWc(
          <tr>
            <th scope="row">Nombre de WC: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.NBRE_WC}</td>
          </tr>
          ) 
        }
        if(bienFromIdInUseEffect.APPARTEMENT.SURFACE_GARAGE){
          setGarage(
          <tr>
            <th scope="row">Surface garage: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.SURFACE_GARAGE} m²</td>
          </tr>
          )
        }
        if(bienFromIdInUseEffect.APPARTEMENT.ANNEE_CONSTRUCTION){
          setAnneeDeConstruction(
          <tr>
            <th scope="row">Année construction: </th>
            <td>{bienFromIdInUseEffect.APPARTEMENT.ANNEE_CONSTRUCTION}</td>
          </tr>
          ) 
        }
      }


      var includesFond = keys.includes("FOND_COMMERCE")
      if(includesFond === true){
        setTypeDeBien("Fonds de Commerce")
        setSurfaceState(`${bienFromIdInUseEffect.FOND_COMMERCE.SURFACE_HABITABLE} m²`)
      }


      var includesGrange = keys.includes("GRANGE")
      if(includesGrange === true){
        setTypeDeBien("Grange")
        setSurfaceState(`${bienFromIdInUseEffect.GRANGE.SURFACE_TERRAIN}  m² `)
      }


      var includesImmeuble = keys.includes("IMMEUBLE")
      if(includesImmeuble === true){
        setTypeDeBien("Immeuble")
        setNombreDePiecesState(`${bienFromIdInUseEffect.IMMEUBLE.NBRE_NIVEAUX} etg  - `)
        setSurfaceState(`${bienFromIdInUseEffect.IMMEUBLE.SURFACE_HABITABLE}  m² `)
      }


      var includesParking = keys.includes("PARKING")
      if(includesParking === true){
        setTypeDeBien("Parking")
        setSurfaceState(`${bienFromIdInUseEffect.PARKING.SURFACE_TERRAIN}  m² `)
      }


      var includesTerrain = keys.includes("TERRAIN")
      if(includesTerrain === true){
        setTypeDeBien("Terrain")
        setSurfaceState(`${bienFromIdInUseEffect.TERRAIN.SURFACE_TERRAIN}  m² `)
      }


      var includesTerrain = keys.includes("LOCAL_PROFESSIONNEL")
      if(includesTerrain === true){
        setTypeDeBien("Local Commercial")
        setNombreDePiecesState(`${bienFromIdInUseEffect.LOCAL_PROFESSIONNEL.NBRE_PIECES} p - `)
        setSurfaceState(`${bienFromIdInUseEffect.LOCAL_PROFESSIONNEL.SURFACE_HABITABLE}  m² `)
      }


      if(bienFromIdInUseEffect.LOCALISATION.PAYS){
        setPays(
        <tr>
          <th scope="row">Pays :</th>
          <td>{bienFromIdInUseEffect.LOCALISATION.PAYS}</td>
        </tr>
        )
      }
      if(bienFromIdInUseEffect.LOCALISATION.CODE_POSTAL){
        setCodePostal(
        <tr>
          <th scope="row">Code Postal :</th>
          <td>{bienFromIdInUseEffect.LOCALISATION.CODE_POSTAL}</td>
        </tr>
        )
      }
      if(bienFromIdInUseEffect.LOCALISATION.VILLE){
        setVille(
        <tr>
          <th scope="row">Ville :</th>
          <td>{bienFromIdInUseEffect.LOCALISATION.VILLE}</td>
        </tr>
        ) 
      }

      if(bienFromIdInUseEffect.PIECES.PIECE != undefined){
      
        var piecesData = []
        for(let i = 0; i<bienFromIdInUseEffect.PIECES.PIECE.length; i++){
          piecesData.push(
            <tr>
              <th scope="row">{bienFromIdInUseEffect.PIECES.PIECE[i].TYPE_PIECE}</th>
              <td>{bienFromIdInUseEffect.PIECES.PIECE[i].SURFACE_PIECE} m²</td>
              <td>{bienFromIdInUseEffect.PIECES.PIECE[i].NIVEAU_PIECE}</td>
            </tr>
  
          )
          
        }
        setPiecesToDisplay(
          <Table striped style={{border: '2px solid white'}}>
            <thead>
              <tr>
                <th>Type de pièce :</th>
                <th>Surface :</th>
                <th>Niveau :</th>
              </tr>
            </thead>
            <tbody>
              {piecesData}
            </tbody>
          </Table>
        )
      }else{
        setPiecesToDisplay(
        <span style={{color: "#206A37", fontSize: 20, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <AiFillWarning style={{marginRight: 5}}/> 
          <span>Pas d'informations sur les pièces</span>
        </span>
        )
      }

      
    if(bienFromIdInUseEffect.ALUR.COPROPRIETE){
      setBienEnCopro(
      <tr>
        <th scope="row">Bien en copropriété :</th>
        <td>{bienFromIdInUseEffect.ALUR.COPROPRIETE}</td>
      </tr>
      ) 
    }
    if(bienFromIdInUseEffect.ALUR.NB_LOTS){
      setNombreLots(
      <tr>
        <th scope="row">Nb Lots Copropriété :</th>
        <td>{bienFromIdInUseEffect.ALUR.NB_LOTS}</td>
      </tr> 
      )
    }
    if(bienFromIdInUseEffect.ALUR.NB_LOTS_HAB){
      setLotsHabitation(
      <tr>
        <th scope="row">Dont lots d'habitation :</th>
        <td>{bienFromIdInUseEffect.ALUR.NB_LOTS_HAB}</td>
      </tr>
      ) 
    }
    if(bienFromIdInUseEffect.ALUR.CHARGES_ANNUELLES){
      setChargesAnnuelles(
      <tr>
        <th scope="row">Charges annuelles :</th>
        <td>{bienFromIdInUseEffect.ALUR.CHARGES_ANNUELLES}</td>
      </tr>
      )
    }
    if(bienFromIdInUseEffect.ALUR.PROCEDURES != 0){
      setProcedures(
      <tr>
        <th scope="row">Procédures :</th>
        <td>{bienFromIdInUseEffect.ALUR.PROCEDURES}</td>
      </tr>
      ) 
    }

      if(userFoundFromToken != null){

        var checkIfBienIfFavoris = await fetch('/isBienInFavoris', {
          method: 'POST',
          headers: {'Accept': 'application/json','Content-Type': 'application/json','Origin': '*'},
          body: JSON.stringify({
            bienIdFromFront: bienFromIdInUseEffect.INFO_GENERALES.AFF_NUM,
            userTokenFromFront: userFoundFromToken
          })
          // body: `bienIdFromFront=${bienFromIdInUseEffect.INFO_GENERALES.AFF_NUM}&userTokenFromFront=${userFoundFromToken}`
        })
        .then((result)=>result.json())
        .then((json)=> {
          if(json.result === true){
            setHeart(true)
          }

        })

      }

    },[]);




  const sendEmail=()=> {

    var conditionsAreOkay = false;

    if(nomClient === ''){
      setNameErrorMessage(<span style={{color: "red"}}>Vous devez rentrer un nom</span>)
    }if(telClient === ''){
      setPhoneErrorMessage(<span style={{color: "red"}}>Vous devez rentrer une numéro de téléphone</span>)
    }else{
      setButtonClicked("Sending email")
  
      var message = "Interresé par ce bien"
      if(isLocation === true){
        message = "Interresé par cette location"
      }
        
      emailjs.send('service_k47enb9', 'template_6p2b1aj', {raison_message: message, nom_client: nomClient, premon_client: prenomClient, tel_client: telClient, bien_interrese: bienFromId.INFO_GENERALES.AFF_NUM, bien_a_vendre: bienAVendre}, 'user_TImKxpycj1WcmG7hCooDa')
        .then((result) => {
            setButtonClicked("Email sent")
        }, (error) => {
        });
    }
  }


  async function handleSauvegarderFavoris(){


    if(userFoundFromToken != null){

      setHeart(true)

      var checkIfBienIfFavoris = await fetch('/addBienToFavoris', {
        method: 'POST',
        headers: {'Accept': 'application/json','Content-Type': 'application/json','Origin': '*'},
        body: JSON.stringify({
          userTokenFromFront: userFoundFromToken,
          bienFromFront: bienFromId,
        })
        // body: `bienFromFront=${bienFromId}&userTokenFromFront=${userFoundFromToken}`
      })

    }else {
      alert("Il faut se connecter pour pouvoir sauvegarder ce bien sur vos favoris")
    }
}

  var sauvegarderButton = 
  <Button style={{backgroundColor: "#206A37", display: "flex", justifyContent: "center", alignItems: "center"}}  onClick={()=>handleSauvegarderFavoris()}>
    <IoMdHeartEmpty style={{color:'white', width: 20, height: 20, marginRight: 5}}/>
    Sauvegarder sur favoris
  </Button>



  if(heart === true){
    sauvegarderButton = 
  <Button style={{backgroundColor: "#206A37", display: "flex", justifyContent: "center", alignItems: "center"}}>
    <GiConfirmed style={{color:'white', width: 20, height: 20, marginRight: 5}} />
    <span>Ce bien est sauvegardé sur vos favoris</span>
  </Button>

  }

  var infoShown;



  function handleBienAvendre(answer){
    setBienAVendre(answer)
  }

  var styleOui;
  var styleNon;
  if(bienAVendre === "Oui"){
    styleOui = {
      textDecoration: "underline",
    }
    styleNon = {
      textDecoration: "none",
    }
  }
  if(bienAVendre === "Non"){
    styleNon = {
      textDecoration: "underline",
    }
    styleOui = {
      textDecoration: "none",
    }
  }

  
  if(buttonClicked === 'Général'){
  

    infoShown = 
      <Col xs="12">
        <Row style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Row style={{display: 'flex', justifySelf: 'center', alignSelf: 'flex-start', marginLeft: '5px', fontSize: 25, color: '#206A37'}}><strong>Général :</strong></Row>
          <Row style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
            <Col xs='6'>
              <Table size="sm" style={{backgroundColor: "#ECEEFF", color: "#206A37", width: "100%"}}>
                <tbody>
                  {etages}
                  {chambres}
                  {salleDeBain}
                  {surfaceHabitable}
                </tbody>
              </Table>
            </Col>
            <Col xs='6'>
              <Table size="sm" style={{backgroundColor: "#ECEEFF", color: "#206A37", width: "100%"}}>
                <tbody>
                  {pieces}
                  {wc}
                  {surfaceTerrain}
                  {garage}
                  {anneeDeConstruction}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Row>
        <Row style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Row style={{display: 'flex', justifySelf: 'center', alignSelf: 'flex-start', marginLeft: '5px', fontSize: 25, color: '#206A37'}}><strong>Description :</strong></Row>
          <Row style={{display: 'flex', width: '98%', textAlign: 'start'}}>{bienCommentaires}</Row>
        </Row>
      </Col>
    
  }

  if(buttonClicked === 'Localisation'){

    // var vue = ""
    // var distanceVoieExpress = ""
    // var distanceCommerces = ""
    // var accesGare = ""
    
    infoShown = 
      <Col xs='6' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <Row style={{width: '100%'}}>
          <Table striped style={{border: '2px solid white'}}>
            <tbody>
              {pays}
              {codePostal}
              {ville}
            </tbody>
          </Table>
        </Row>
      </Col>
  }

  if(buttonClicked === 'Pieces'){  

    infoShown =
    <span style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Col xs='6' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10}}>
        {piecesToDisplay}
      </Col>
    </span>

  }

  if(buttonClicked === 'Coproriete'){

    infoShown = 
    <Col xs="8">
        <Table striped style={{border: '2px solid white'}}>
          <tbody>
            {bienEnCopro}
            {nombreLots}
            {lotsHabitation}
            {chargesAnnuelles}
            {procedures}
          </tbody>
        </Table>
    </Col>

  }

  if(buttonClicked === 'Visiter'){

    var displayBienAVendre;
    if(isVente === true){
      displayBienAVendre = 
      <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: 3}}>
      <Row>Avez-vous un bien à vendre?</Row>
      <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
        <ButtonGroup style={{width: '100%'}}>
          <Button color="success" onClick={() => handleBienAvendre("Oui")} active={bienAVendre === 1} style={styleOui} >Oui</Button>
          <Button color="success" onClick={() => handleBienAvendre('Non')} active={bienAVendre === 2} style={styleNon} >Non</Button>
         </ButtonGroup>
      </Row>
    </Row>
    }

    infoShown = 

    <Col xs='12' lg='4'>
          <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#206A37',flexDirection: 'column', marginBottom: 2}}>
            <Row style={{fontSize: 20}}><strong>Envie de visiter ?</strong></Row>
            <Row style={{fontSize: 20, textAlign: 'center'}}><strong>Une question sur cet appartement ?</strong></Row>
          </Row>
          <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {nameErrorMessage}
            <Input placeholder='Nom' style={styleInput} onChange={(e)=>setNomClient(e.target.value)}></Input>
          </Row>
          <Row>
            <Input placeholder='Prénom' style={styleInput} onChange={(e)=>setPrenomClient(e.target.value)}></Input>
          </Row>
          <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {phoneErrorMessage}
            <Input placeholder='Téléphone' style={styleInput} onChange={(e)=>setTelClient(e.target.value)}></Input>
          </Row>
          {displayBienAVendre}
          <Row>
            <Button color="success" style={{color: 'white', width: '100%'}} onClick={sendEmail}>Contacter l’agence</Button>
          </Row>
    </Col>
  }

  if(buttonClicked === "Sending email"){
    infoShown = <Spinner color="success"  style={{marginBottom: 10}}/>
  }

  if(buttonClicked === 'Email sent'){

    infoShown = <span style={{fontSize: 25, color: '#206A37', textAlign: 'center', padding: 10}}>Votre demande a été envoyé! Nous vous répondrons dans les plus brefs délais.</span>
  }



  return (
<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{opacity: 0 }}
>

      <Container style={BackgroundImage}>

        <NavBar pageName="&nbsp;" />


        <Row style={descRow}>

          <Row style={{width: "100%", marginBottom: 30, display: "flex", justifyContent: "flex-start"}}>
            <Link to="/resultats" style={{marginRight: 15}}>
              <Button style={{color: "#206A37", backgroundColor: "white"}}>
                <BiArrowBack/>
                Revenir aux Resultats
              </Button>
            </Link>
          </Row>

          <Col xs='12' lg='6' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', alignSelf: 'center', justifySelf: 'center', backgroundColor: "transparent"}}>
            <PictureCarousel idFromUrl={idFromUrl}/>
          </Col>

        </Row>

        <Row style={descRow2}>
          <Col xs='12' lg='6'>
            <Row style={{marginLeft: '3px', fontSize: 25}}><strong>{typeDeBien}</strong></Row>
            <Row style={{marginLeft: '3px'}}>
              {nombreDePiecesState}
              {nombresDeChambresState}
              {surfaceState}
            </Row>
            <Row style={{marginLeft: '3px'}}>{bienVille} - {bienCodePostal}</Row>
          </Col>

          <Col xs='12' lg='6' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column'}}>
            <Row style={{marginRight: '3px', fontSize: 25}}>{prixDuBien} €</Row>
            <Row style={{marginRight: '3px'}}>
            {sauvegarderButton}
            </Row>
          </Col>
        </Row>

        <Row style={descRow3}>
          <Col xs='12' lg='2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button style={styleButton} onClick={()=> setButtonClicked('Général')}>Général</Button>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button style={styleButton} onClick={()=> setButtonClicked('Localisation')}>Localisation</Button>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button style={styleButton} onClick={()=> setButtonClicked('Pieces')}>Pièces</Button>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button style={styleButton} onClick={()=> setButtonClicked('Coproriete')}>Coproriete</Button>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Button style={styleButton} onClick={()=> setButtonClicked('Visiter')}>Visiter</Button>
          </Col>
        </Row>

        <Row style={descRow4}>
          {infoShown}
        </Row>

        
      </Container>
      <Footer/>

</motion.div>
  );
}



var styleInput = {
  border: '2px solid #206A37',
  margin: 3
}

var styleButton = {
  backgroundColor: '#206A37',
  color: 'white',
  width: 150,
  border: 0
}

var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height:'auto',
  backgroundImage: "linear-gradient(to right top, #569e67, #86b57a, #b1cb92, #d9e3ae, #fffbce)",
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  maxWidth: '100%',
  padding: 0,
}


var descRow = {
  display: 'flex',
  flexDirection: "column",
  justifySelf: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 'calc(1em + 0.5vw)',
  width: '100%'
}

var descRow2 = {
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'space-between',
  marginTop: 'calc(1em + 1vw)',
  marginBottom: 20,
  width: '80%',
  backgroundColor: 'rgba(32.0, 106.0, 55.0, 1.0)',
  borderRadius: 10,
  color: 'white',
  padding: '5px'
}

var descRow3 = {
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,
  width: '80%',
  backgroundColor: '#206A37',
  borderRadius: 10
}

var descRow4 = {
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 1,
  marginBottom: 'calc(1em + 0.5vw)',
  width: '80%',
  border: '2px solid #206A37',
  borderRadius: 10,
  padding: '15px',
  backgroundColor: 'rgba(255,255,255, 0.8)'
}



export default PageBien
