export default function(trierPar="Prix décroissant", action){
    if(action.type === 'addTrier'){
        if(action.thisTrier === "Prix croissant"){
            var trierParPrixCroissant = action.thisTrier
            return trierParPrixCroissant
        }else if(action.thisTrier === "Prix décroissant"){
            var trierParPrixDecroissant = action.thisTrier
            return trierParPrixDecroissant
        }
    }else{
        return trierPar;
    }
}