import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col, Popover, Input, PopoverBody, Button, Badge  } from 'reactstrap';
import photo from './images/pageFavoris.png'
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
import user from './images/user.png'
import Footer from './Footer'
import { motion } from 'framer-motion'
import { FaUserCircle } from 'react-icons/fa';
import { BsFillTrashFill } from 'react-icons/bs';
import {connect} from "react-redux"
import NavBar from "./NavBar"
import { parse } from 'fast-xml-parser';
import {Tooltip, IconButton} from '@material-ui/core/';








function Wishlist(props) {

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    const [logInMessage, setLogInMessage] = useState([])

  const [logInAccepted, setLogInAccepted] = useState(false)

  const [userGenre, setUserGenre] = useState('')
  const [usersName, setUsersName] = useState('')
  const [deleteFav, setDeleteFav] = useState(false)
  const [biensFavoris, setBiensFavoris] = useState([])




  const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))

  var handleLogout = () => {
      localStorage.removeItem('usersToken')
      setLogInAccepted(false)
  }
  
      
  useEffect(async() => {


      if(userFoundFromToken != null){

          
      var rawData = await fetch('/sendToken', {
          method: 'POST',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: `tokenFromFront=${userFoundFromToken}`
    }); 
    
        var data = await rawData.json()
        
        if(data.result === true){
          setLogInAccepted(true)
          setUsersName(data.user.prenom)
          setUserGenre(data.user.genre)
        }

      }
      
      var wishlistFromDataBase;
      var toutBiens;
      var biensToDisplay = []

      var getBiensFavoris = await fetch('/getBiensFavoris', {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          userTokenFromFront: userFoundFromToken,
        })
      })
        .then(response => response.json()
          .then(json => wishlistFromDataBase = json.tableauDeBiensFavoris ))

          setBiensFavoris(wishlistFromDataBase)
            
    },[]);



  async function deleteBienFavoris(ref){

    var deleteBien = await fetch('/supprimerBienFavori', {
      method: 'DELETE',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        refFromFront: ref,
        userTokenFromFront: userFoundFromToken,
      })
    })
    .then(response => response.json()
    .then(json => setBiensFavoris(json.tableauDeBiensFavoris)))
    
  }
  
  
  return (
    
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{opacity: 0 }}
>

      <Container style={BackgroundImage}>

        <NavBar pageName="M E S &nbsp; F A V O R I S" />
        {
          biensFavoris.map(function(bien, i){
            
            var bienImage = bien.IMAGES.IMG[0]
            var caption100 = bien.COMMENTAIRES.FR.substring(0,200) + " ...";
            var typeDeBien;
            var nbDeChambres;
            var nbDePieces;
            var prixDuBien;
            var surface;

            var keys = Object.keys(bien)
            var includesMaison = keys.includes("MAISON")
            var typeOfImages = typeof bien.IMAGES.IMG

            if(typeOfImages === "string"){
              bienImage = bien.IMAGES.IMG
            }

                  if(includesMaison === true){
                    typeDeBien = "Maison"
                    nbDeChambres = bien.MAISON.NBRE_CHAMBRES
                    nbDePieces = bien.MAISON.NBRE_PIECES
                    surface = bien.MAISON.SURFACE_HABITABLE
                  }
                  var includesApp = keys.includes("APPARTEMENT")
                  if(includesApp === true){
                    typeDeBien = "Appartement"
                    if(bien.APPARTEMENT.CATEGORIE === "Loft"){
                      typeDeBien = "Loft"
                    }
                    nbDeChambres = bien.APPARTEMENT.NBRE_CHAMBRES
                    nbDePieces= bien.APPARTEMENT.NBRE_PIECES
                    surface = bien.APPARTEMENT.SURFACE_HABITABLE
                  }
                  var includesFond = keys.includes("FOND_COMMERCE")
                  if(includesFond === true){
                    typeDeBien = "Fonds de Commerce"
                    nbDeChambres = bien.FOND_COMMERCE.NBRE_CHAMBRES
                    nbDePieces = bien.FOND_COMMERCE.NBRE_PIECES
                    surface = bien.FOND_COMMERCE.SURFACE_HABITABLE
                  }
                  var includesGrange = keys.includes("GRANGE")
                  if(includesGrange === true){
                    typeDeBien = "Grange"
                    nbDeChambres = bien.GRANGE.NBRE_CHAMBRES
                    nbDePieces = bien.GRANGE.NBRE_PIECES
                    surface = bien.GRANGE.SURFACE_HABITABLE
                  }
                  var includesImmeuble = keys.includes("IMMEUBLE")
                  if(includesImmeuble === true){
                    typeDeBien = "Immeuble"
                  }
                  var includesParking = keys.includes("PARKING")
                  if(includesParking === true){
                    typeDeBien = "Parking"
                  }
                  var includesTerrain = keys.includes("TERRAIN")
                  if(includesTerrain === true){
                    typeDeBien = "Terrain"
                  }
                  var includesVente = keys.includes("VENTE")
                  if(includesVente === true){
                    prixDuBien = numberWithSpaces(bien.VENTE.PRIX)
                  }
                  var includesLocation = keys.includes("LOCATION")
                  if(includesLocation === true){
                    prixDuBien = numberWithSpaces(bien.LOCATION.LOYER)
                  }
  
                  
            
                    return(
                    <span to='/bien'  style={firstRow} key={i} onClick={()=>props.onBienClick(bien)}>
                        <Col xs='12' lg='3' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center', height:'100%'}}>
                          <Link to='/bien' style={linkStyle}>
                            <img src={bienImage} style={{width: '200px', height: '200px', display:'flex', justifySelf: 'center', alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}} fluid />
                          </Link>
                        </Col>
            
                      
                      <Col xs='12' lg='9' style={{display:'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column'}}>

                        <Link to="/bien" style={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center"}}>
                            <Row style={{width: "100%", marginBottom: 10, color: "#206A37", display: "flex", justifyContent: "space-between", }}>
                              <Col xs="12" lg="10">
                                <Row>{typeDeBien}</Row>
                                <Row><strong> {nbDePieces} p - {nbDeChambres}  ch - {surface} m² </strong></Row>
                              </Col>
                              <Col xs="12" lg="2">
                                <Badge style={{backgroundColor: '#206A37', fontSize:'calc(0.5em + 0.5vw)'}}>{prixDuBien} €</Badge>
                              </Col>
                            </Row>

                            <Row style={{width: "100%", display: "flex", flexDirection: "column", marginBottom: 10, color: "#206A37"}}>
                              <span>{bien.LOCALISATION.VILLE} - {bien.LOCALISATION.CODE_POSTAL}</span>
                            </Row>
                        </Link>
                        
            
                          <Row style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                            <Col xs="12" lg="10">
                            <Link to="/bien" styme={linkStyle}>
                              <span style={{fontSize: 'calc(0.5em + 0.4vw)', color: "#a6a6a6",}}>{caption100}</span>
                            </Link>
                            </Col>
                            <Col xs="12" lg="2" style={{display: "flex", justifyContent: "flex-end"}}>
                              <Tooltip title="Supprimer" onClick={()=>deleteBienFavoris(bien)} style={{width: "100%"}}>
                                <IconButton aria-label="delete">
                                  <BsFillTrashFill />
                                </IconButton>
                              </Tooltip>
                            </Col>
                          </Row>
                      </Col>

                    </span>
                    )
                  })
            }

      </Container>
      <Footer/>

</motion.div>
  );
}

function mapDispatchToProps(dispatch){
  return {
    onBienClick: function(bienInfo){
      dispatch( {type: "addBienData", thisData: bienInfo} )
    }
  }
}


var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height:'auto',
  backgroundImage: `url(${photo})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  maxWidth: '100%',
  padding: 0,
}

var navBarRow ={
  backgroundColor: 'white',
  height: 'auto',
  diplay: 'flex',
  flexDirection: 'row',
  justifySelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1vw',
  opacity: '90%'
}

var firstRow = {
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255, 0.9)',
  width: '60%',
  justifySelf: 'center',
  alignSelf: 'center',
  marginTop: 'calc(0.5em + 0.5vw)',
  marginBottom: 'calc(0.5em + 0.5vw)',
  borderRadius: '10px',
  border: 0
}

var linkStyle = {
  width: "100%",
  display: 'flex',
  color: "#206A37"
}




export default connect(
  null,
  mapDispatchToProps
)(Wishlist)