import React, {useEffect, useState} from "react"
import 'antd/dist/antd.css';
import imageOne from './images/ActualitéesFonds.png'
import imageTwo from './images/HomePageFond.png'
import imageThree from './images/NosAgencesFonds.png'
import pasDeImage from './images/pasimage.jpg'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import {connect} from "react-redux"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'antd';


function CarrouselBien(props) {

const [photosFromRedux, setPhotosFromRedux] = useState()
const [photosToDisplay, setPhotosToDisplay] = useState([])
const [imageExpand, setImageExpand] = useState()
const [onePhoto, setOnePhoto] = useState()
const [noPhoto, setNoPhoto] = useState(false)


const images = []



useEffect(async()=> {


  var getBien = await fetch("/getBienFromId", {
    method: 'POST',
    headers: {'Content-Type':'application/x-www-form-urlencoded', 'Origin': '*'},
    body: `idFromFront=${props.idFromUrl}`
   })
   .then(response => response.json())
   .then(obj => {
    setPhotosFromRedux(obj.thisBien.IMAGES.IMG)

    var type = typeof obj.thisBien.IMAGES.IMG

    if(obj.thisBien.IMAGES.IMG === undefined){
      setPhotosToDisplay(
        <div  style={{width: "auto", height: "450px", cursor: "zoom-in"}}>
          <Image src={pasDeImage} style={{cursor: "zoom-in"}} width="auto" height="450px"/>
        </div>
      )
    }else if(type === "string"){
      setPhotosToDisplay(
        <div  style={{width: "auto", height: "450px", cursor: "zoom-in"}}>
          <Image src={obj.thisBien.IMAGES.IMG} style={{cursor: "zoom-in"}} width="auto" height="450px"/>
        </div>
      )
    }else{
      setPhotosToDisplay(

        obj.thisBien.IMAGES.IMG.map(function(image, i){
            return(
              <div key={i} style={{width: "auto", height: "450px", cursor: "zoom-in",   backgroundImage: "linear-gradient(to right top, #569e67, #86b57a, #b1cb92, #d9e3ae, #fffbce)"}}>
                  <Image src={image} style={{cursor: "zoom-in"}} width="auto" height="450px"/>
              </div>
            )
          })
        

      )
    }

  })

}, [])


// Condition if photo is only 1 (string)


 // normal case

  return(
      <span>
      <Carousel showThumbs={false} centerMode={true} centerSlidePercentage={100} dynamicHeight={false} style={{display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center", backgroundColor: "transparent"}}>
        {photosToDisplay}
      </Carousel>
    </span>
  )
}

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

function mapStateToProps(state) {
  return {
    bienToDisplay: state.bienToDisplay
  }
}

export default connect(
  mapStateToProps,
  null
)(CarrouselBien);