import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Popover, Input, PopoverBody, Button,  } from 'reactstrap';
import photo from './images/PageQuiSommesNous.png'
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
import avis1 from './images/avis2.jpg'
import Footer from './Footer'
import {motion} from 'framer-motion'
import NavBar from "./NavBar"
import ReactStars from 'react-stars'






function QuiSommesNous() {
    
    const [rating, setRating] = useState()
    const [nomClient, setNomClient] = useState("")
    const [emailClient, setEmailClient] = useState("")
    const [titreAvis, setTitreAvis] = useState("")
    const [hasTitre, setHasTitres] = useState(false)
    const [avisClient, setAvisClient] = useState("")
    const [isRated, setIsRated] = useState(false)
    const [errorMessageRating, setErrorMessageRating] = useState('')
    const [errorMessageTitre, setErrorMessageTitre] = useState('')
    
    const ratingChanged = (newRating) => {
        console.log(newRating)
        setRating(newRating)
        setIsRated(true)
      }

    const titreChanges = (e) => {
        setTitreAvis(e.target.value)
        setHasTitres(true)
    }

async function validateAvis() {

    if(isRated === true && hasTitre === true){

        var getSaveFiltersInRecherche = await fetch('/publishAvis', {
            method: 'POST',
            headers: {'Content-Type':'application/json', 'Origin': '*'},
            body: JSON.stringify({
                evaluationFromFront: rating,
                nomFromFront: nomClient,
                emailFromFront: emailClient,
                titreFromFront: titreAvis,
                avisFromFront: avisClient,
            })
        })
      
          var data = getSaveFiltersInRecherche.json()
    
          console.log("data :", data)

    }else{
        setErrorMessageRating(<span style={{color: "red"}}>Ce champs est obligatoire!</span>)
        setErrorMessageTitre(<span style={{color: "red"}}>Ce champs est obligatoire!</span>)
    }

}


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{opacity: 0 }}
    >
      <Container style={BackgroundImage}>

        <NavBar/>

        <Row style={boxAround}>
            <span style={boxAround1}>Laissez ici votre avis sur nos agences!</span>
            <span style={{fontWeight: 'bold'}} >Évaluez votre expérience récente :</span>
            {errorMessageRating}
            <ReactStars
            count={5}
            onChange={ratingChanged}
            size={50}
            color2={'#ffd700'}
             />
            <span style={{fontWeight: 'bold'}}>Votre NOM et Prénom :</span>
            <Input size="sm" placeholder='Nom et Prénom' style={{marginBottom: 5, width: "30%"}} onChange={(e)=>setNomClient(e.target.value)}></Input>
            <span style={{fontWeight: 'bold'}}>Votre adresse mail :</span>
            <Input size="sm" placeholder='E-mail' style={{marginBottom: 5 , width: "30%"}} onChange={(e)=>setEmailClient(e.target.value)}></Input>
            <span style={{fontWeight: 'bold'}}>Donnez un titre à votre avis :</span>
            {errorMessageTitre}
            <Input size="sm" placeholder='Titre' style={{marginBottom: 5 , width: "100%"}} onChange={(e)=>titreChanges(e)}></Input>
            <span style={{fontWeight: 'bold'}}>Racontez-nous votre expérience :</span>
            <Input size="sm" placeholder='Message' type="textarea" style={{height: 'calc(2em + 12vw)'}} onChange={(e)=>setAvisClient(e.target.value)}></Input>
            <Button style={validerStyle} onClick={validateAvis}>Valider</Button>
        </Row>



      </Container>
      <Footer/>
      
    </motion.div>
  );
}

var validerStyle = {
    color: "white",
    backgroundColor: "#206A37",
    marginTop: 10
}

var boxAround1 = {
    justifySelf: 'center',
    alignSelf: 'center',
    fontSize: 25,
    color: '#206A37',
    marginBottom: 50,
    fontWeight: 'bold'

}

var boxAround = {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifySelf: 'center',
    alignSelf: 'center',
    backgroundColor: 'rgba(255,255,255, 0.9)', 
    padding: 20,
    borderRadius: 10,
    flexDirection: "column",
    marginBottom: 30


}

var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  height:'auto',
  backgroundImage: `url(${photo})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  maxWidth: '100%',
  padding: 0,
}

var navBarRow ={
  backgroundColor: 'white',
  height: 'auto',
  diplay: 'flex',
  flexDirection: 'row',
  justifySelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1vw',
  opacity: '90%'
}

var descRow = {
  display: 'flex',
  flexDirection: 'column',
  justifySelf: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 'calc(1em + 5vw)'
}

var col1 = {
  display: 'flex', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  flexDirection: 'column', 
  backgroundColor: 'rgba(255,255,255, 0.7)', 
  justifyContent: 'center', 
  alignItems: 'center',
  borderRadius: 10,
}

var titleStyle = {
  display: 'flex', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  padding: '2vh', 
  color: '#206A37', 
  paddingTop: '4vh', 
  fontSize: 'calc(1em + 2vw)', 
  textAlign: 'center'
}

var span1Style = {
  display: 'flex', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  padding: '2vh', 
  textAlign: 'justify', 
  fontSize: 'calc(0.4em + 0.5vw)'
}

var span2Style = {
  display: 'flex', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  padding: '1vh', 
  textAlign: 'justify', 
  fontSize: 'calc(0.4em + 0.5vw)'
}

var span3Style = {
  display: 'flex', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  paddingRight: '2vh', 
  paddingLeft: '2vh', 
  paddingBottom: '2vh', 
  textAlign: 'justify', 
  fontSize: 'calc(0.4em + 0.5vw)'
}

export default QuiSommesNous;