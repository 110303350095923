import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Popover, Input, PopoverBody, Badge, Label, Button, ButtonGroup  } from 'reactstrap';
import { Link } from 'react-router-dom';
import { parse } from 'fast-xml-parser';
import {connect} from "react-redux"



function ViagerApp(props){

    const [vgApp, setvgApp] = useState([])

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    var pasdeBien;


    useEffect(async()=>{

        var toutBiens = [];
        var toutViager = [];

        var viagerApp = [];
        

        var iconv = require('iconv-lite');

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "MOLSESSION=S1839|YE+Vr|YE+Vr");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
        
        await fetch("/getXMLfile")
        .then(response => response.json())
        .then(obj => {
          console.log("obj :", obj)
          toutBiens = obj.jsonOb.LISTEPA.BIEN
          console.log("toutBiens :", toutBiens)
  
            for(let i = 0; i<toutBiens.length; i++){
                if(toutBiens[i].VIAGER.EN_VIAGER === "Oui"){
                    toutViager.push(toutBiens[i])
                }
            }

            for(let i=0; i<toutViager.length; i++){
                var keys = Object.keys(toutViager[i])
                var includesApp = keys.includes("APPARTEMENT")
                if(includesApp === true){
                  viagerApp.push(toutViager[i])
                }
              }
              setvgApp(viagerApp)
  
          })
          .catch(error => console.log('error', error)) 


          
          
        }, [])
        
        if(vgApp.length === 0){
         pasdeBien = "Il n'y a pas de biens correspondant à votre recherche..."
      }

    return(
        <Container style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexDirection: "column"}}>
            <span style={{display: "flex", justifyContent: "center", alignItems: "center", color: "red", marginTop: 10}}>{pasdeBien}</span>

            {
                vgApp.map(function(bien, i){
  
                    var bienImage;
                    var caption100 = bien.COMMENTAIRES.FR.substring(0,200) + " ...";
                  
                    if(typeof bien.IMAGES.IMG === "object"){
                        bienImage = bien.IMAGES.IMG[0]
                    }else{
                        bienImage = bien.IMAGES.IMG
                    }
            
                    return(
                    <Link to='/bien' xs="12" lg="10" style={firstRow} key={i} onClick={()=>props.onBienClick(bien)}>
                      <Col xs='12' lg='3' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', justifySelf: 'center', alignSelf: 'center', height:'100%'}}>
                        <img src={bienImage} style={{width: '200px', height: '200px', display:'flex', justifySelf: 'center', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', borderRadius: "10px 0px 0px 10px"}} fluid />
                      </Col>
            
                      
                      <Col xs='12' lg='9' style={{display:'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column'}}>
            
                          <Row style={{width: "100%", marginBottom: 10}}>
                              <Col xs="9">
                                <Row>Appartement</Row>
                                <Row><strong> {bien.APPARTEMENT.NBRE_PIECES} p - {bien.APPARTEMENT.NBRE_CHAMBRES} ch - {bien.APPARTEMENT.SURFACE_HABITABLE} m² </strong></Row>
                              </Col>
                              <Col xs="3">
                                  <Badge style={{backgroundColor: '#206A37', fontSize:'calc(0.5em + 0.5vw)'}}>{numberWithSpaces(bien.LOCATION.LOYER)} € / mois</Badge>
                              </Col>
                          </Row>
            
                          <Row style={{width: "100%", display: "flex", flexDirection: "column", marginBottom: 10}}>
                            <span>{bien.LOCALISATION.VILLE}</span>
                            <span>{bien.LOCALISATION.CODE_POSTAL}</span>
                          </Row>
            
                          <Row style={{width: "100%"}}>
                              <span style={{fontSize: 'calc(0.5em + 0.4vw)', color: "#a6a6a6"}}>{caption100}</span>
                          </Row>
                      </Col>
                  </Link>
                    )
                  })
            }
        </Container>
    )

}

function mapDispatchToProps(dispatch){
  return {
    onCheckLength: function(length){
      dispatch( {type: "addLength", thisLength: length})
    },
    onBienClick: function(bienInfo){
      dispatch( {type: "addBienData", thisData: bienInfo} )
    }
  }
}

var firstRow = {
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    padding: 0,
    width: '100%',
    justifySelf: 'center',
    alignSelf: 'center',
    marginTop: 'calc(0.5em + 0.5vw)',
    marginBottom: 'calc(0.5em + 0.5vw)',
    borderRadius: '10px',
    border: 0,
    color: '#206A37'
  }

export default connect(
  null,
  mapDispatchToProps
  )(ViagerApp)