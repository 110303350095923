import React, { useState, useEffect } from 'react';
import '@brainhubeu/react-carousel/lib/style.css';
import { Col, Row, Modal,  ModalHeader, ModalBody, Button, ModalFooter} from 'reactstrap';
import "react-image-gallery/styles/css/image-gallery.css"
import photo2 from './images/instaBackground.png'
import { RiCloseCircleLine } from 'react-icons/ri';
import { FaInstagram } from 'react-icons/fa';
import { Card } from 'antd';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
  } from "react-share";




function Carrosel(props) {

    const { Meta } = Card;


    function changeWeight(e) {
        e.target.style.fontWeight = 'bolder';
    }
    
    function changeBackWeight(e) {
        e.target.style.fontWeight = 'normal';
    }

    const {
        className
      } = props;

    const [modal, setModal] = useState(false);
    const [allPostsCaptions, setAllPostsCaptions] = useState()
    const [postsInReturnn, setPostsInReturn] = useState()
    const toggle2 = () => setModal(!modal);
    const [mediaPostData, setMediaPostData] = useState()
    const [textPostData, setTextPostData] = useState()
    const [typePostData, setTypePostData] = useState()
    const [datePostData, setDatePostData] = useState()
    const [allPosts, setAllPosts] = useState()

    var InstaPostData = [];
    var instaModal;

    if(typePostData === 'IMAGE' || typePostData === "CAROUSEL_ALBUM"){

        instaModal =   <Modal isOpen={modal} toggle={toggle2} className={className} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <ModalHeader style={{display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}>
                                <Button style={{backgroundColor: 'white', border: 'white'}}><RiCloseCircleLine onClick={toggle2} style={{color:'#656D64', width: 20, height: 20, display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}/></Button>
                            </ModalHeader>
                            <ModalBody>
                                <img src={mediaPostData} style={{width: '100%'}}/>
                            </ModalBody>
                            <ModalBody>
                                <snap style={{textAlign: 'left', fontFamily: 'roboto, sans-serif', fontWeight: '300', fontStyle: 'normal', fontSize: 14}}>{textPostData}</snap>
                            </ModalBody>
                            <ModalFooter>
                                <span style={{fontFamily: 'roboto, sans-serif', fontWeight: '300', fontStyle: 'normal', fontSize: 14}}>{datePostData}</span>
                            </ModalFooter>
                        </Modal>
    }else{
        instaModal = <Modal isOpen={modal} toggle={toggle2} className={className} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ModalHeader style={{display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}>
                            <Button style={{backgroundColor: 'white', border: 'white'}}><RiCloseCircleLine onClick={toggle2}  style={{color:'black', width: 20, height: 20, display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}/></Button>
                        </ModalHeader>
                        <ModalBody>
                            <video controls style={{width: '100%'}} fluid>
                                <source src={mediaPostData} />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ModalBody>
                        <ModalBody>
                            <snap style={{textAlign: 'justify'}}>{textPostData}</snap>
                        </ModalBody>
                        <ModalFooter>
                            <span>{datePostData}</span>
                        </ModalFooter>
                    </Modal>
    }
   

    function handleClickModal(post) {
        setMediaPostData(post.imgUrl)
        setTextPostData(post.caption)
        setTypePostData(post.type)
        var date = new Date(post.date);
        var year = date.getFullYear();
        var month = date.getMonth()+1;
        var dt = date.getDate();

        if (dt < 10) {
        dt = '0' + dt;
        }
        if (month < 10) {
        month = '0' + month;
        }

        setDatePostData(dt+'-' + month + '-'+year)
        toggle2()
         
        
    }

    var tempoPosts2 = []



    useEffect(async() => {


            
        var rawData = await fetch('https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,timestamp,thumbnail_url&access_token=IGQWRQakpXbFpreUdoNTFvNFdmN0pJSTd2X1JJdWFwWnE2NkFfNEZAMREZAxRkJxNlhKcFBHbXAteFN1YWJGVE45clpKMmw4ZAlEzdW10aHVId2M4QUlPNTJLNl9nR1NDelF5clN1NFBtMHZADTFNTTlhad1ZApblR1YlEZD'); 
      
        var data = await rawData.json()

        var realData = data.data;

        for(let i = 0; i<12; i++){
            InstaPostData.push({caption: realData[i].caption, 
                thumbnailUrl: realData[i].thumbnail_url, 
                imgUrl: `${realData[i].media_url}`, 
                type: realData[i].media_type, 
                date: realData[i].timestamp})
        }

        // for(let i = 0; i<realData)

        setAllPostsCaptions(InstaPostData)

        

        var tempoPosts = InstaPostData.map(function(i) {
            if(i.type === 'IMAGE' || i.type === "CAROUSEL_ALBUM"){
                return(
                    <Col xs="12" sm="6" lg="3" style={{marginBottom: 20, display: "flex", justifySelf: "center", alignSelf: "center", justifyContent: "center", alignItems: "center" }} onClick={()=>handleClickModal(i)}>
                        <Card
                        hoverable
                        style={{ width: 300, marginRight: 5, marginLeft: 5, height: 450, minHeight: 450, overflow: "hidden" }}
                        cover={<img alt="example" src={i.imgUrl} style={{minHeight: 250, maxHeight: 250}}/>}
                        >
                        <div style={{marginTop: 5, marginBottom: 15, display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center",flexDirection: "column"}}>
                            <FaInstagram style={{width: 20, height: 20, marginRight: 5}} />
                            <span style={{fontFamily: "proxima-nova, sans-serif", fontSize: 15}}>@fcimmo</span>
                        </div>   
                        <Meta description={i.caption} style={{textAlign: "center"}} />
                        </Card>
                    </Col>
                    
            )
        }
        if(i.type === 'VIDEO'){
            return(
                // <Row onMouseOver={changeWeight} onMouseOut={changeBackWeight} onClick={()=>handleClickModal(i)} style={styleRow}>
                //     <Col xs='10' lg='3' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                //         <img src={i.thumbnailUrl} style={{width: 'inherit', height: 'inherit', border: "1px solid white"}} fluid/>
                //     </Col>
                //     <Col xs='10' lg='8'  style={styleCol}>
                //         <span style={{textAlign: 'left', fontSize: 'calc(0.5em + 0.4vw)', height: 'inherit'}}>{i.caption}</span>
                //     </Col>
                // </Row>
                <Col xs="12" sm="6" lg="3" style={{marginBottom: 20, display: "flex", justifySelf: "center", alignSelf: "center", justifyContent: "center", alignItems: "center"  }} onClick={()=>handleClickModal(i)}>
                <Card
                    hoverable
                    style={{ width: 300, marginRight: 5, marginLeft: 5, height: 450, minHeight: 450, overflow: "hidden" }}
                    cover={<img alt="example" src={i.thumbnailUrl} style={{minHeight: 250, maxHeight: 250}} />}
                    >
                    <div style={{marginTop: 5, marginBottom: 15, display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center",flexDirection: "column"}}>
                        <FaInstagram style={{width: 20, height: 20, marginRight: 5}} />
                        <span style={{fontFamily: "proxima-nova, sans-serif", fontSize: 15}}>@fcimmo</span>
                    </div>
                    <Meta description={i.caption} style={{textAlign: "center"}} />
                    </Card>
                </Col>
                
        )
        }
        })


        setAllPosts(tempoPosts)
        

        // for(let i=0; i<5; i++){
        //     tempoPosts2.push(tempoPosts[i])
        // }

        // setPostsInReturn(tempoPosts2)

      },[]);
      
      
      // End of useEffect here


    function handlePlusArticles() {
        setPostsInReturn(allPosts)
    }

    return (

    <div style={divStyle}>
        <Row style={{display: "flex", justifyContent: "center", width: "100%"}}>
            {allPosts}
        </Row>
        {instaModal}
        {/* <EmailShareButton url="https://sleepy-sea-58614.herokuapp.com/" subject="Regarde ce bien sur F.C.I!" body="Je te partage ce bien que j'ai vu sur F.C.I : ">
            <EmailIcon/>
        </EmailShareButton>
        <FacebookShareButton url="https://sleepy-sea-58614.herokuapp.com/" quote="Ragardez ce bien sir F.C.I">
            <FacebookIcon/>
        </FacebookShareButton>
        <LinkedinShareButton url="https://sleepy-sea-58614.herokuapp.com/" title="F.C.I" summary="Regardez ce bien que j'ai trouvé sur F.C.I">
          <LinkedinIcon/>
        </LinkedinShareButton>
        <TwitterShareButton url="https://sleepy-sea-58614.herokuapp.com/">
          <TwitterIcon/>
        </TwitterShareButton> */}
    </div>

    )
  
}

var divStyle = {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',  
    height: '100%', 
    alignSelf: 'stretch', 
    width: '100%', 
    fontFamily: 'roboto, sans-serif', 
    fontStyle: 'normal'
}

var styleRow = {
    justifyContent: 'center', 
    alignItems: 'center', 
    justifySelf: 'center', 
    alignSelf: 'center', 
    backgroundColor: "transparent", 
    width: '100%', 
    margin: '20px 0 20px 0', 
    borderBottom: '1px solid white', 
    borderTop: '1px solid white',
}

var styleCol = {
    display: 'flex', 
    justifySelf: 'center', 
    alignSelf: 'center', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center'
}



export default Carrosel



// const images = [
//     {
//       original: "https://i.imgur.com/b3xdn1x.jpg",
//       thumbnail: "https://i.imgur.com/b3xdn1x.jpg",
//     },
//     {
//       original: 'https://static.dezeen.com/uploads/2020/02/house-in-the-landscape-niko-arcjitect-architecture-residential-russia-houses-khurtin_dezeen_2364_hero-852x479.jpg',
//       thumbnail: 'https://static.dezeen.com/uploads/2020/02/house-in-the-landscape-niko-arcjitect-architecture-residential-russia-houses-khurtin_dezeen_2364_hero-852x479.jpg'
//     },
//     {
//       original: 'https://www.rocketmortgage.com/resources-cmsassets/RocketMortgage.com/Article_Images/Large_Images/TypesOfHomes/types-of-homes-hero.jpg',
//       thumbnail: 'https://www.rocketmortgage.com/resources-cmsassets/RocketMortgage.com/Article_Images/Large_Images/TypesOfHomes/types-of-homes-hero.jpg'
//     }
//   ]

{/* <ImageGallery items={images} /> */}



{/*  */}

{/* <Modal isOpen={modal} toggle={toggle} className={className}>
                <img src={imageOne} onClick={toggle} style={{width: '150vh', display: 'flex', justifySelf: 'center', alignSelf: 'center'}}/>
            </Modal> */}