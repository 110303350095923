import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Popover, Input, PopoverBody, Button, Badge, Table   } from 'reactstrap';
import photo from './images/mesRecherches.png'
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
import user from './images/user.png'
import { BsFillTrashFill } from 'react-icons/bs';
import Footer from './Footer'
import { motion } from 'framer-motion'
import { FaUserCircle } from 'react-icons/fa';
import { connect } from "react-redux"
import { Tag } from 'antd';
import { FaMapMarkerAlt } from 'react-icons/fa';
import NavBar from "./NavBar"
import {Tooltip, IconButton} from '@material-ui/core/';






function MesRecherches(props) {

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
}

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    const [logInMessage, setLogInMessage] = useState([])

    const [logInAccepted, setLogInAccepted] = useState(false)
  
  
    const [userGenre, setUserGenre] = useState('')
    const [usersName, setUsersName] = useState('')
  
    const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))
    const [tableauDeMesRecherches, setTableauDeMesRecherches] = useState([])



    var handleLogout = () => {
      localStorage.removeItem('usersToken')
      setLogInAccepted(false)
  }
  
      
  useEffect(async() => {


      if(userFoundFromToken != null){

          
      var rawData = await fetch('/sendToken', {
          method: 'POST',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: `tokenFromFront=${userFoundFromToken}`
    }); 
    
        var data = await rawData.json()
        
        if(data.result === true){
          setLogInAccepted(true)
          setUsersName(data.user.prenom)
          setUserGenre(data.user.genre)
        }

      }
      
      var tempoTableau = []
      var tableauFiltered = []
      var getMesRecherces = await fetch('/getMesRecherches', {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          userTokenFromFront: userFoundFromToken,
        })
      })
        .then(response => response.json()
          .then(json =>  tempoTableau = json.tableauDeMesRecherches))

          setTableauDeMesRecherches(tempoTableau)

       
    },[]);



  var handleSignIn = async () => {


        var rawData = await fetch('/signIn', {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `emailFromFront=${email}&passwordFromFront=${password}`
      });

      var data = await rawData.json()
      if(data.result == true){
        setLogInAccepted(true)
        setUsersName(data.usersName)
        localStorage.setItem('usersToken', data.token)
      }else{
        setLogInMessage(data.errors)

      }

  }

  var userBoard = <PopoverBody style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>Se connecter</span>
                      <Input type="email" placeholder="Email" style={{marginBottom: '1vh', width:'auto'}} onChange={(e) => setEmail(e.target.value)}></Input>
                      <Input type="password" placeholder="Password" style={{marginBottom: '1vh', width:'auto'}} onChange={(e) => setPassword(e.target.value)}></Input>
                      <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>{logInMessage}</span>
                      <Button style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}} onClick={()=>handleSignIn()}>Confirmer</Button>
                      <Button style={{width: '28vh', backgroundColor: '#206A37'}}><Link to='/creationdecompte' style={{color: 'white'}}>Créer un compte</Link></Button>
                  </PopoverBody>

  if(logInAccepted === true){
    userBoard = <PopoverBody style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>Bienvenue {userGenre} {usersName} !</span>
                    <Button size='sm' style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}}><Link to='/wishlist' style={{color: 'white'}}>Voir mes favoris</Link></Button>
                    <Button size='sm' style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}}><Link to='/mesrecherches'>Voir mes dernieres recherches</Link></Button>
                    <Button size='sm' style={{width: '28vh', backgroundColor: '#206A37'}} onClick={()=>handleLogout()}>Se déconecter</Button>
                </PopoverBody>
  }


  async function handleDelete(id){

    var getBiensFavoris = await fetch('/deleteMaRecherche', {
      method: 'DELETE',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        userTokenFromFront: userFoundFromToken,
        idFromFront: id
      })
    })
      .then(response => response.json()
        .then(json => setTableauDeMesRecherches(json.tableauDeMesRecherches)))
        }
        
        
  function onUtiliserCesFiltresClick(recherche){

    var appBoolean = false
    var maisonBoolean = false
    var loftBoolean = false
    var terrainBoolean = false
    var bureauBoolean = false
    var fondCommerceBoolean = false
    var hotelPBoolean = false
    var immeubleBoolean = false
    var parkingBoolean = false
    var grangeBoolean = false
    var noBiensBoolean = false
    
    
    if(recherche.typeDeBien.includes("Appartement") === true){
      appBoolean = true
    }
    if(recherche.typeDeBien.includes("Maison") === true){
      maisonBoolean = true
    }
    if(recherche.typeDeBien.includes("Loft") === true){
      loftBoolean = true
    }
    if(recherche.typeDeBien.includes("Terrain") === true){
      terrainBoolean = true
    }
    if(recherche.typeDeBien.includes("Bureau") === true){
      bureauBoolean = true
    }
    if(recherche.typeDeBien.includes("Fond de Commerce") === true){
      fondCommerceBoolean = true
    }
    if(recherche.typeDeBien.includes("Hotel Particulier") === true){
      hotelPBoolean = true
    }
    if(recherche.typeDeBien.includes("Immeuble") === true){
      immeubleBoolean = true
    }
    if(recherche.typeDeBien.includes("Parking / Box") === true){
      parkingBoolean = true
    }
    if(recherche.typeDeBien.includes("Grange") === true){
      grangeBoolean = true
    }

    if(appBoolean === false &&
      bureauBoolean === false &&
      fondCommerceBoolean  === false &&
      grangeBoolean   === false &&
      hotelPBoolean === false &&
      immeubleBoolean === false &&
      loftBoolean === false &&
      maisonBoolean === false &&
      parkingBoolean === false &&
      terrainBoolean === false){
        noBiensBoolean = true
      }
    var convertedFilters = {
      typeDeTransaction: recherche.typeDeTransaction,
      budgetMax: recherche.budgetMax,
      budgetMin: recherche.budgetMin,
      citiesMap: recherche.mapBounds,
      citiesTableau: recherche.localisation,
      appartement: appBoolean,
      bureau: bureauBoolean,
      fondCommerce: fondCommerceBoolean,
      grange: grangeBoolean,
      hotelP: hotelPBoolean,
      immeuble: immeubleBoolean,
      loft: loftBoolean,
      maison: maisonBoolean,
      parking: parkingBoolean,
      terrain: terrainBoolean,
      noBiensSelected: noBiensBoolean,
    }
    props.onClickValider(convertedFilters)


  }
  

  return (
<motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{opacity: 0 }}
>
      <Container style={BackgroundImage}>

        <NavBar pageName="M E S &nbsp; R E C H E R C H E S" />


        <Col xs="12" lg="4" style={titreStyle}>
          <span>Voici vos dernieres recherches :</span>
        </Col>

        { tableauDeMesRecherches.map(function(recherche, i){

          var typedeTransaction = 
          <tr>
            <th scope="row">Type de Transaction :</th>
            <td>{recherche.typeDeTransaction}</td>
          </tr>

          var typeBien;
          var budgetMin;
          var budgetMax;
          var localisation;
          var surfaceMin;
          var surfaceMax;
          var terrainMin;
          var terrainMax;
          var nombrePieces;
          var nombreChambres;

          if(recherche.typeDeBien[0] != "Vous n'avez pas séléctionné un type de bien"){
            typeBien = 
            <tr>
              <th scope="row">Type de Bien :</th>
              <td>{recherche.typeDeBien.join(', ')}</td>
            </tr>
          }
          if(recherche.budgetMin != ""){
            budgetMin = 
            <tr>
              <th scope="row">Budget Min :</th>
              <td>{recherche.budgetMin}</td>
            </tr>
          }
          if(recherche.budgetMax != ""){
            budgetMax = 
            <tr>
              <th scope="row">Budget Max :</th>
              <td>{recherche.budgetMax}</td>
            </tr>
            
          }
          if(recherche.localisation.length != 0){

            var villes = []

            for(let i = 0; i < recherche.localisation.length; i++){
              villes.push(recherche.localisation[0].city)
            }
              localisation = 
              <tr>
                <th scope="row">Localisation:</th>
                <td>{villes.join(', ')}</td>
              </tr>
          }
          if(recherche.mapBounds.length != 0){
            localisation = 
            <tr>
              <th scope="row">Localisation:</th>
              <td>
                <Tag icon={<FaMapMarkerAlt style={{marginRight: 3}} />} color="success" style={{padding: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>
                  Coordonnées de la carte
                </Tag>
              </td>
            </tr>
          }
          if(recherche.surfaceMin != undefined){
            surfaceMin = 
            <tr>
              <th scope="row">Surface Min:</th>
              <td>{numberWithSpaces(recherche.surfaceMin)}</td>
            </tr>
          }
          if(recherche.surfaceMax != undefined){
            surfaceMax = 
            <tr>
              <th scope="row">Surface Max:</th>
              <td>{numberWithSpaces(recherche.surfaceMax)}</td>
            </tr>
          }
          if(recherche.terrainMin != undefined){
            terrainMin =
            <tr>
              <th scope="row">Terrain Min:</th>
              <td>{numberWithSpaces(recherche.terrainMin)}</td>
            </tr>
          }
          if(recherche.terrainMax != undefined){
            terrainMax = 
            <tr>
              <th scope="row">Terrain Max:</th>
              <td>{numberWithSpaces(recherche.terrainMax)}</td>
            </tr>
          }
          if(recherche.nbPieces != undefined){
            nombrePieces =
            <tr>
              <th scope="row">Nombre de pièces:</th>
              <td>{recherche.nbPieces}</td>
            </tr>
          }
          if(recherche.nbChambres != undefined){
            nombreChambres =
            <tr>
              <th scope="row">Nombre de chambres:</th>
              <td>{recherche.nbChambres}</td>
            </tr>
          }
          var pasDeType = false
          if(recherche.typeDeBien[0] === "Vous n'avez pas séléctionné un type de bien"){
            pasDeType = true
          }

          return(

                <Col xs="12" lg="5" style={rechercheStyle}>
                  <Row style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"}}>
                    <span style={{color: "#206A37", fontWeight: "bold", marginBottom: 20}}>Filtres :</span>
                    <Table size="sm" style={{backgroundColor: "#ECEEFF", color: "#206A37", width: "auto"}}>
                      <tbody>
                        {typedeTransaction} 
                        {typeBien}
                        {budgetMin}
                        {budgetMax}
                        {localisation}
                        {surfaceMin}
                        {surfaceMax}
                        {terrainMin}
                        {terrainMax}
                        {nombrePieces}
                        {nombreChambres}
                      </tbody>
                    </Table>
                  </Row>
                  <Row style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: 20}}>
                    <Col xs="12" lg="2" style={{color: "#888888", display: "flex" , justifyContent: "center", marginBottom: 5}}>
                      <span>{convert(recherche.date)}</span>
                    </Col>
                    <Col xs="12" lg="8" style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 5}}>
                      <Button onClick={()=>onUtiliserCesFiltresClick(recherche)} style={{backgroundColor: '#206A37', fontSize: 12}}>
                        <Link to="/resultats" style={{color: "white"}}>
                          Utiliser ces filtres de recherche
                        </Link>
                      </Button>
                    </Col>
                    <Col xs="12" lg="2" style={{display: "flex" , justifyContent: "center", marginBottom: 5}}>
                      {/* <BsFillTrashFill onClick={()=>handleDelete(recherche._id)} style={{height: 25, width: 25, color: "#206A37", cursor: "pointer"}}/> */}
                      <Tooltip title="Supprimer" onClick={()=>handleDelete(recherche._id)} style={{width: "100%"}}>
                        <IconButton aria-label="delete">
                          <BsFillTrashFill />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

          )
        })

        }
      </Container>
      <Footer/>
</motion.div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onClickValider: function(filtres) {
      dispatch( {type: "addFilters", thisFilters: filtres} )
    }
  }
}

var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height:'auto',
  backgroundImage: `url(${photo})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  maxWidth: '100%',
  padding: 0,
}


var titreStyle = {
  display: "flex", 
  justifyContent: "center", 
  alignItems: "center", 
  backgroundColor: 'rgba(255,255,255, 0.9)', 
  color: '#206A37', 
  fontWeight: "bold", 
  marginTop: 20, 
  marginBottom: 20, 
  justifySelf: "center", 
  alignSelf: "center", 
  padding: 10, 
  borderRadius: 10, 
  fontSize: 20
}

var rechercheStyle = {
  display: "flex", 
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  justifySelf: "center",
  alignSelf: "center",
  backgroundColor: 'rgba(255,255,255, 0.9)', 
  marginTop: 20, 
  marginBottom: 20, 
  width: "70%", 
  padding: 10, 
  borderRadius: 10, 
}


export default connect(
  null,
  mapDispatchToProps
)(MesRecherches)