export default function(recherche = {}, action) {

    if(action.type === "addFilters"){
        var newRecherche = action.thisFilters;
        console.log("Redux :", newRecherche)
        return newRecherche
    } else {
        console.log("recherche :", recherche)
        return recherche
    }
}