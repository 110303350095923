import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import photo from './images/NewHomePage.jpg'
import photo2 from './images/FondsActualité.png'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux'
import Footer from './Footer'
import Actualités from './car'
import { motion } from 'framer-motion'
import ReactGa from 'react-ga'
import NavBar from "./NavBar"
import fetch from 'node-fetch';
import { BsArrowRight } from "react-icons/bs";
import { RiVipDiamondLine } from "react-icons/ri";





function HomePage(props) {



    const [screenSizeState] = useState(window.innerWidth)

    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);

        
    useEffect(async() => {

        localStorage.setItem('Type de Bien', "Maison");
        localStorage.setItem('Agency', "");
        localStorage.setItem('Budget Min', "");
        localStorage.setItem('Budget Max', "");



        toggle()

        ReactGa.initialize('G-B2WQ759JDR')

        ReactGa.pageview('/')

        fetch('http://clients.ac3-distribution.com/office2/gli_111306/cache/export.xml', 
        {
            method: "GET",
            headers: {
                
            }
        });

 
      },[]);




    
function changeWeight(e) {
    e.target.style.fontWeight = 'bolder';
}

function changeBackWeight(e) {
    e.target.style.fontWeight = 'normal';
}



let agencyAxes =

<Row  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
    <Col className="hidden-xs" sm="12" md="12" lg='8' style={{backgroundColor: 'white', opacity: '80%', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.5vw',fontSize: 15, color: '#206A37', fontWeight: 'bold'}}>
        <Col xs='4' lg='4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span>GESTION LOCATIVE</span>
        </Col>
        <Col xs='4' lg='4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span>TRANSACTION</span>
        </Col>
        <Col xs='4' lg='4' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span>SYNDIC</span>
        </Col>
    </Col>
</Row>

if(screenSizeState < 576){
    agencyAxes = null
}

function onClickTransaction(type){
    localStorage.setItem('Type de Bien', "Maison et Appartement");
    props.onTypeClick(type)
    localStorage.setItem("transaction", type)
    props.onMapValider([])
}

function onClickVente(){
    onClickTransaction("Acheter")
    localStorage.setItem('Budget Max', 700000)
}



    return(

<motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{opacity: 0 }}
>

    <Container style={BackgroundImage1}>
            
        <NavBar/>

        <Row style={nameWhiteBlock}>
            <Col xs='10' md='3' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0, backgroundColor: 'white', borderRadius: 5,}}>
                <Row  style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: "column", marginTop: "10px"}}>
                    <h1 style={{color: '#206A37', fontSize: 'calc(2em + 3vw)', marginBottom: "3px"}}>FCI</h1>
                    <h5 style={{color: '#206A37', fontSize: 'calc(1em + 0.5vw)'}}>Françoise Combes Immobilier</h5>
                    <Row style={{display: "flex", justifyContent: "center", alignItems: "center", color: "#206A37", width: "100%", marginBottom: 10}}>
                        <span>PARIS, HOUDAN, MAULE</span>
                    </Row>
                    <Row style={{backgroundColor: "#206A37", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white", width: "100%", marginBottom: 10, fontFamily: 'roboto, sans-serif',}}>
                        <span>GESTION LOCATIVE</span>
                        <span>TRANSACTION</span>
                        <span>SYNDIC</span>
                    </Row>
                </Row>
            </Col>
        </Row>
        <Row style={{display: "flex", justifyContent: "center", alignItems: "start", flexDirection: "column", marginLeft: 30,}}>
            <Col xs='10' md='3'  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 0}}>
                <span style={prestigeButton}>
                    <RiVipDiamondLine size="2x" color='#fff' style={{ width: "20px", color: "#fff", marginRight: "3%"}} />
                    <Link to='/resultats' onMouseOver={changeWeight} onMouseOut={changeBackWeight} onClick={()=>localStorage.setItem('Budget Min', 700000)} style={{color: 'white', marginRight: "3%"}}>FCI Prestige</Link>
                    <BsArrowRight size="2x" style={{ width: "20px", color: "#fff"}} />
                </span>
                <span style={littlewhiteboxes}>
                        <Link to='/resultats' onMouseOver={changeWeight} onMouseOut={changeBackWeight} style={{color: '#206A37', marginRight: "3%"}} onClick={ () => onClickVente()}>Nos appartements et maisons en vente</Link>
                        <BsArrowRight size="2x" style={{ width: "20px", color: "#206A37"}} />
                </span>
                <span style={littlewhiteboxes}>
                        <Link to='/resultats' onMouseOver={changeWeight} onMouseOut={changeBackWeight} style={{color: '#206A37', marginRight: "3%"}} onClick={ () => onClickTransaction("Louer") }>Nos appartements et maisons en location</Link>
                        <BsArrowRight size="2x" style={{ width: "20px", color: "#206A37"}} />
                </span>
                <span style={littlewhiteboxes}>
                        <Link to='/estimer' onMouseOver={changeWeight} onMouseOut={changeBackWeight} style={{color: '#206A37', marginRight: "3%"}} onClick={()=>props.onContactClick('Je veux vendre mon bien.')}>Vous souhaitez vendre</Link>
                        <BsArrowRight size="2x" style={{ width: "20px", color: "#206A37"}} />
                </span>
                <span style={littlewhiteboxes}>
                        <Link to='/syndic' onMouseOver={changeWeight} onMouseOut={changeBackWeight} style={{color: '#206A37', marginRight: "3%"}} onClick={()=>props.onContactClick('Je veux changer de Syndic.')}>Vous souhaitez changer de syndic</Link>
                        <BsArrowRight size="2x" style={{ width: "20px", color: "#206A37"}} />
                </span>
                <span style={littlewhiteboxes}>
                        <Link to='/resultats' onMouseOver={changeWeight} onMouseOut={changeBackWeight} onClick={()=> localStorage.setItem('Type de Bien', 'Terrain')} style={{color: '#206A37', marginRight: "3%"}}>Nos terrains en vente</Link>
                        <BsArrowRight size="2x" style={{ width: "20px", color: "#206A37"}} />
                </span>
            </Col>
        </Row>

        <Row style={{marginTop: 50, display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column', width: '100%'}}>

            <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="94da2c03-51f7-4bd6-af7f-31e028775a9e" data-blockingmode="auto" type="text/javascript"></script>


        </Row>

        {/* <AvisClient/> */}


    </Container>

    <Container style={BackgroundImage2}>

        <Row style={navBarRow}>
            <Col xs='12' style={{display: 'flex', justifyContent: 'center'}}>
                <span style={{color: '#206A37', fontSize: '4vw'}}>
                     A C T U A L I T E S
                </span>
            </Col>
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', justifySelf: 'center', alignSelf: 'center', marginTop: '20px', marginBottom: '20px'}}>
            <Actualités/>
        </Row>

        
        
    </Container>

    <Footer/>


</motion.div>
    )
}



function mapDispatchToProps(dispatch) {
    return {
        onContactClick: function(reason) {
            dispatch( {type: 'addReason', whatReason: reason})
        },
        onTypeClick : function(type) {
            dispatch( {type: "addType", whatType: type})
        },
        onMapValider: function(cities) {
          dispatch( {type: "addCitiesFromMap", whatCities: cities})
        }
    }
}


let BackgroundImage1 = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height:'auto',
    backgroundImage: `url(${photo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    maxWidth: '100%',
    fontFamily: 'roboto, sans-serif',
    fontStyle: 'normal', 
    padding: 0,
  }

let navBarRow ={
    backgroundColor: 'white',
    height: 'auto',
    diplay: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1vw',
}

let nameWhiteBlock = {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    marginLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Gloock, serif',
}

let littlewhiteboxes = {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5vw',
    fontSize: '2vh',
    borderRadius: 5,
    color: '#206A37',
    marginBottom: "5px",
    width: "100%",
    textAlign: "center"
}

let prestigeButton = {
    backgroundColor: '#206A37',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5vw',
    fontSize: '3vh',
    borderRadius: 5,
    color: 'white',
    marginBottom: "5px",
    width: "100%"

}



let BackgroundImage2 = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height:'auto',
    backgroundImage: `url(${photo2})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    maxWidth: '100%',
}



export default connect (
    null,
    mapDispatchToProps
) (HomePage);
