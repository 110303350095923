import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Modal,  ModalHeader, ModalBody, Button, ModalFooter, Container} from 'reactstrap';
import Footer from './Footer'
import { motion } from 'framer-motion'
import NavBar from "./NavBar"
import { Card } from 'antd';
import { RiCloseCircleLine } from 'react-icons/ri';
import { Image } from 'antd';
import { Carousel } from 'react-responsive-carousel';








function BiensVendues(props) {

    const [biensToShow, setBiensToShow] = useState([])
    const [modal, setModal] = useState(false);
    const [bienPhoto, setBienPhoto] = useState("")
    const [bienTitle, setBienTitle] = useState("")
    const [bienComment, setBienComment] = useState("")
    const [bienDate, setBienDate] = useState("")


    const { Meta } = Card;

    const {
        className
      } = props;

    var toutBiens = [];
    var biensVendus = []

    const toggle2 = () => setModal(!modal);


    useEffect(async ()=> {
        var getXml = await fetch("/getXMLfile", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Origin: "*",
            },
            body: JSON.stringify({
              emxaple: "hey",
            }),
          })
            .then((response) => response.json())
            .then((obj) => {
              console.log("obj:", obj)
              toutBiens = obj.jsonOb.LISTEPA.BIEN;

              for(let i = 0; i<toutBiens.length; i++){
                if(toutBiens[i].INFO_GENERALES.STATUT == "Vendu"){
                    biensVendus.push(toutBiens[i])
                }
              }
              console.log("biensVendus :", biensVendus)
              setBiensToShow(biensVendus)
      
            })
    },[])


    function handleClickModal(bien) {
        console.log("bien.IMAGES.IMG :", bien.IMAGES.IMG)
        setBienPhoto(bien.IMAGES.IMG)
        setBienTitle(bien.INTITULE.FR)
        setBienComment(bien.COMMENTAIRES.FR)
        setBienDate(bien.INFO_GENERALES.DATE_MAJ)

        toggle2()
         
    }




  return (

<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{opacity: 0 }}
>

      <Container style={BackgroundImage}>

      <NavBar/>
      <Row style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <Row style={{fontSize: 35, fontWeight: "bold", color: "#206A37", marginBottom: 55}}>NOS BIENS VENDUS</Row>
        <Row style={{width: "100%"}}>

                {
                    biensToShow.map(function(i) {
                        return(
                            <Col xs="12" sm="6" lg="3" style={{marginBottom: 20, display: "flex", justifySelf: "center", alignSelf: "center", justifyContent: "center", alignItems: "center" }} onClick={()=>handleClickModal(i)}>
                                <Card
                                hoverable
                                style={{ width: 300, marginRight: 5, marginLeft: 5, height: 450, minHeight: 450, overflow: "hidden" }}
                                cover={<img alt="example" src={i.IMAGES.IMG[0]} style={{minHeight: 250, maxHeight: 250}}/>}
                                >   
                                <Meta description={i.COMMENTAIRES.FR} style={{textAlign: "center"}} />
                                </Card>
                            </Col>

                        )
                    })
                }
        </Row>
      </Row>


        { modal ? (
            <Modal isOpen={modal} toggle={toggle2} className={className} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: "60%"}}>
                <ModalHeader style={{display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}>
                    <Button style={{backgroundColor: 'white', border: 'white'}}><RiCloseCircleLine onClick={toggle2} style={{color:'#656D64', width: 20, height: 20, display: 'flex', alignSelf: 'flex-end', justifySelf: 'flex-end'}}/></Button>
                </ModalHeader>
                <ModalBody>
                    {/* <img src={bienPhoto} style={{width: '100%'}}/> */}
                    <Carousel showThumbs={false} centerMode={true} centerSlidePercentage={100} dynamicHeight={false} style={{display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center", backgroundColor: "transparent"}}>
                    {bienPhoto.map(function (image, i) {
                        return (
                            <div key={i} style={{width: "auto", height: 350, cursor: "zoom-in",   backgroundColor: "white"}}>
                            <Image src={image} style={{cursor: "zoom-in"}} width="auto" height="100%"/>
                        </div>
                        );
                    })}
                    </Carousel>
                </ModalBody>
                <ModalHeader style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                    <span>{bienTitle}</span>
                </ModalHeader>
                <ModalBody>
                    <snap style={{textAlign: 'left', fontFamily: 'roboto, sans-serif', fontWeight: '300', fontStyle: 'normal', fontSize: 14}}>{bienComment}</snap>
                </ModalBody>
                <ModalFooter>
                    <span style={{fontFamily: 'roboto, sans-serif', fontWeight: '300', fontStyle: 'normal', fontSize: 14}}>{bienDate}</span>
                </ModalFooter>
            </Modal>
        ):(<span></span>)

        }


        
        


      </Container>
      <Footer/>

</motion.div>
  );
}



var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height:'auto',
  backgroundImage: "linear-gradient(to right top, #569e67, #86b57a, #b1cb92, #d9e3ae, #fffbce)",
  // backgroundColor: "#92CC60",
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  maxWidth: '100%',
  minWidth: "100%",
  padding: 0,
}

var text1 = {
    fontSize: 30
}

var iconStyle = {
    width: 50,
    height: 50
}

var col1 = {
    display: 'flex', 
    justifySelf: 'center', 
    alignSelf: 'center', 
    flexDirection: 'column', 
    backgroundColor: 'rgba(255,255,255, 0.7)', 
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius: 10,
    width: "50%"
  }


export default BiensVendues;
