import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Popover, Input, PopoverBody, Button, Label  } from 'reactstrap';
import photo from './images/PageCreationDeCompte.png'
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
import user from './images/user.png'
import Footer from './Footer'
import { motion } from 'framer-motion'
import { FaUserCircle } from 'react-icons/fa';
import NavBar from "./NavBar"



function CreationDeCompte() {

    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    const [genre, setGenre] = useState('')
    const [nom, setNom] = useState('')
    const [prenom, setprenom] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelephone] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [tableauErreurs, setTableauErreurs] = useState([]);
    const [userCreated, setUserCreated] = useState('')

    const [signInEmail, setSignInEmail] = useState('');
    const [signInpassword, setSignInpassword] = useState('')

    const [logInMessage, setLogInMessage] = useState([])

  const [logInAccepted, setLogInAccepted] = useState(false)

  const [usersName, setUsersName] = useState('')
  const [userGenre, setUserGenre] = useState('')

  const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))
  const [compteCree, setCompteCree] = useState(false)
  var handleLogout = () => {
    localStorage.removeItem('usersToken')
    setLogInAccepted(false)
}




   var handleConfirmer = async ()  =>  {

    


    var rawData = await fetch('/signUp', {
      method: 'POST',
      headers: {'Content-Type':'application/json', 'Origin': '*'},
      body: JSON.stringify({
        genreFromFront: genre,
        nomFromFront: nom,
        prenomFromFront: prenom,
        emailFromFront: email,
        telephoneFromFront: telephone,
        passwordFromFront: password,
        passwordConfirmationFromFront: passwordConfirmation
      })
});

    var data = await rawData.json();

    if(data.result == true){
      setUserCreated('Votre compte à été créé!')
      setCompteCree(true)
      localStorage.setItem('usersToken', data.userSaved.token)
      // window.location.reload()
      // setCompteCree(true)
    }else{
      setTableauErreurs(data.errors)
    }



   }

   var listOfErrors = tableauErreurs.map((error, i)=>{
     return(<span>{error}</span>)
   })


   let infoToShow = 

  <Row>
    <Row style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "#206A37", width: "100%"}}>
      <span style={{marginBottom: 15, fontSize: 20, fontWeight: "bold"}}>La création d'une compte utilisateur permet :</span>
      <span style={{marginBottom: 5, fontWeight: "bold"}}>1. Ajouter des bien dans une liste de favoris</span>
      <span style={{marginBottom: 20, fontWeight: "bold"}}>2. Avoir accées à son historique de recherches</span>
    </Row>
    <Row style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 'calc(1em + 2vw)'}}>
        <Col style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Label check style={{marginRight: '25px'}}>
                <Input type="radio" name="radio1" value="Madame" onChange={(e) => setGenre(e.target.value)} />{' '}
                Mme.
            </Label>
            <Label check>
                <Input type="radio" name="radio1" value="Monsieur" onChange={(e) => setGenre(e.target.value)} />{' '}
                M.
            </Label>
        </Col>
    </Row>
    <Row style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 'calc(0.2em + 0.2vw)'}}>
        <Col xs='12' lg='5'>
            <Input placeholder="Nom" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setNom(e.target.value)}/>
        </Col>
        <Col xs='12' lg='5'>
            <Input placeholder="Prénom" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setprenom(e.target.value)}/>
        </Col>
    </Row>
    <Row style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 'calc(0.2em + 0.2vw)'}}>
        <Col xs='12' lg='5'>
            <Input placeholder="Email" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setEmail(e.target.value)}/>
        </Col>
        <Col xs='12' lg='5'>
            <Input placeholder="Telephone" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setTelephone(e.target.value)}/>
        </Col>
    </Row>
    <Row style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 'calc(1em + 2vw)'}}>
        <Col xs='12' lg='5'>
            <Input type="password" placeholder="Mot de passe" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setPassword(e.target.value)} />
        </Col>
        <Col xs='12' lg='5'>
            <Input type="password" placeholder="Confirmer me mot de passe" style={{border: '2px solid #206A37', borderRadius: 10}} onChange={(e) => setPasswordConfirmation(e.target.value)}/>
        </Col>
    </Row>
    <Row style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red', flexDirection: 'column', textAlign: 'center'}}>
          {listOfErrors}
        </span>
        <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#206A37', flexDirection: 'column', textAlign: 'center'}}>
          {userCreated}
        </span>
        <Button style={{color: 'white', backgroundColor: '#206A37', marginTop: '5px'}} onClick={()=>handleConfirmer()}>Confirmer</Button>
    </Row>
  </Row>


if(compteCree === true){
  infoToShow = 
    <Row style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <span style={{color: "#206A37", fontWeight: "bold", marginBottom: 15, fontSize: 25}}>Votre compte à été crée!</span>
      <Button style={{backgroundColor: "#206A37"}}>
        <Link to="/" style={{color: "white"}}>
          Page d'Accueil
        </Link>
      </Button>
    </Row>
}


  return (
<motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{opacity: 0 }}
>

      <Container style={BackgroundImage}>

      <NavBar compte={compteCree}/>


        <Row style={descRow}>
          {infoToShow}
        </Row>

      </Container>
      <Footer/>
    </motion.div>
  );
}



var BackgroundImage = {
    display: 'flex',
    flexDirection: 'column',
    minHeight:'100vh',
    height: "auto",
    backgroundImage: `url(${photo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: '100%',
    padding: 0,
  }
  
  
  var descRow = {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'calc(1em + 5vw)',
    marginBottom: 'calc(1em + 5vw)',
    backgroundColor: 'rgba(255,255,255, 0.7)',
    borderRadius: 10,
    paddingTop: 'calc(1em + 1vw)',
    paddingBottom: 'calc(1em + 1vw)'
  }
  
  

export default CreationDeCompte;