import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Popover, Input, PopoverBody, Button  } from 'reactstrap';
import photo from './images/NosAgencesFonds.png'
import { Link } from 'react-router-dom';
import logo from './images/logo.png'
import agenceParis from './images/fciparis2.png'
import agenceMaule from './images/fcimaule.png'
import agenceHoudan from './images/fcihoudan.png'
import agenceSyndic from './images/agency2.png'
import agenceGestion from './images/agency1.png'
import Footer from './Footer'
import { motion } from 'framer-motion'
import { FaUserCircle } from 'react-icons/fa';
import NavBar from "./NavBar"
import { Card } from 'antd';





function NosAgences() {

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

  const [logInMessage, setLogInMessage] = useState([])

  const [logInAccepted, setLogInAccepted] = useState(false)

  const [userGenre, setUserGenre] = useState('')
  const [usersName, setUsersName] = useState('')

  const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))

  var handleLogout = () => {
      localStorage.removeItem('usersToken')
      setLogInAccepted(false)
  }
  
      
  useEffect(async() => {


      if(userFoundFromToken != null){

          
      var rawData = await fetch('/sendToken', {
          method: 'POST',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: `tokenFromFront=${userFoundFromToken}`
    }); 
    
        var data = await rawData.json()
        
        if(data.result === true){
          setLogInAccepted(true)
          setUsersName(data.user.prenom)
          setUserGenre(data.user.genre)
        }

      }


       
    },[]);



  var handleSignIn = async () => {


        var rawData = await fetch('/signIn', {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `emailFromFront=${email}&passwordFromFront=${password}`
      });

      var data = await rawData.json()
      if(data.result == true){
        setLogInAccepted(true)
        setUsersName(data.usersName)
        localStorage.setItem('usersToken', data.token)
      }else{
        setLogInMessage(data.errors)

      }

  }

  var userBoard = <PopoverBody style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>Se connecter</span>
                      <Input type="email" placeholder="Email" style={{marginBottom: '1vh', width:'auto'}} onChange={(e) => setEmail(e.target.value)}></Input>
                      <Input type="password" placeholder="Password" style={{marginBottom: '1vh', width:'auto'}} onChange={(e) => setPassword(e.target.value)}></Input>
                      <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>{logInMessage}</span>
                      <Button style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}} onClick={()=>handleSignIn()}>Confirmer</Button>
                      <Button style={{width: '28vh', backgroundColor: '#206A37'}}><Link to='/creationdecompte' style={{color: 'white'}}>Créer un compte</Link></Button>
                  </PopoverBody>

  if(logInAccepted === true){
    userBoard = <PopoverBody style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{padding: '1vh', color: '#206A37', fontWeight: 'bold'}}>Bienvenue {userGenre} {usersName} !</span>
                    <Button size='sm' style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}}><Link to='/wishlist' style={{color: 'white'}}>Voir mes favoris</Link></Button>
                    <Button size='sm' style={{width: '28vh', marginBottom: '1vh', backgroundColor: '#206A37'}}><Link to='/mesrecherches' style={{color: 'white'}}>Voir mes dernieres recherches</Link></Button>
                    <Button size='sm' style={{width: '28vh', backgroundColor: '#206A37'}} onClick={()=>handleLogout()}>Se déconecter</Button>
                </PopoverBody>
  }

  const { Meta } = Card;


  

  return (

<motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{opacity: 0 }}
>
      <Container style={BackgroundImage}>

        <NavBar/>

        <Row style={agenceRow}>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginTop: '1%', marginBottom: '1%'}}>
            <img src={agenceParis} style={{width: '100%', height: '100%'}} fluid />
          </Col>
          <Col xs='12' lg='7' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
            <span style={{fontSize:'calc(1em + 0.5vw)', fontWeight: "bold"}}>FCI PARIS Transactions</span>
            <span style={{fontSize: 'calc(0.7em + 0.2vw)', textAlign: 'justify', marginTop: 5}}>
              Au cœur du 16 eme arrondissement, depuis 20 ans, une agence de quartier dont l’avantage est de
              connaitre parfaitement le bâti Parisien et notre belle capitale.
              Ici vous pourrez consulter appartements et propriétés intra et extra muros.
              Achats et ventes, les transactions immobilières n’ont plus de secret pour Paul Kammerer.
              A l’écoute de vos projets, investisseurs, primo-accédants, célibataires ou familles, chaque client est
              privilégié chez nous.
              Vos estimations seront étudiées parfaitement et au meilleur prix.
              La vente de votre appartement sera suivie et efficace.
              Profitez d’un accompagnement personnalisé.
            </span>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginBottom: '1%', marginTop: '1%'}}>
            <span style={contactInfo}>
              {/* <span>Tél. : 01.40.50.20.20</span> */}
              <a href="tel:0140502020" style={{color: 'white'}}>Tél. : 01.40.50.20.20 </a>
              <a href='mailto:mozart@fcimmo.com' style={{color: 'white', textDecoration: "underline"}}>mozart@fcimmo.com</a>
              <span>53 avenue Mozart</span>
              <span>75016 PARIS</span>
            </span>
          </Col>
        </Row>

        <Row style={agenceRow}>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginTop: '1%', marginBottom: '1%'}}>
            <img src={agenceHoudan} style={{width: '100%', height: '100%'}} fluid />
          </Col>
          <Col xs='12' lg='7' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
            <span style={{fontSize:'calc(1em + 0.5vw)', fontWeight: "bold"}}>FCI HOUDAN Transactions</span>
            <span style={{fontSize: 'calc(0.7em + 0.2vw)', textAlign: 'justify', marginTop: 5}}>
              Bientôt 30 ans de présence fidèle et efficace, notre réputation n’est plus à faire. Les avis clients sont
              la preuve de notre sérieux. Nous restons l’agence phare de Houdan et de sa région.
              Rejoignez les clients qui nous suivent depuis longtemps maintenant.
              Ecoute, disponibilité, efficacité sont les atouts que nous mettons à votre disposition.
              Christelle Menelec, François Choquet, Franck Goddard auront le plaisir de vous accueillir à Houdan,
              jolie bourgade vivante et commerçante à moins d’une heure de Paris, par la route ou avec un pass
              navigo par transports en commun.
              Ici, c’est la campagne chic et agréable, découvrez ce bel environnement.
              Notre large portefeuille va d’une charmante petite maison à une vaste et prestigieuse propriété de
              caractère.
              Vous pouvez également nous solliciter pour estimer votre propriété.
            </span>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginBottom: '1%', marginTop: '1%'}}>
            <span style={contactInfo}>
              <a href="tel:0178905151" style={{color: 'white'}}>Tél. : 01.78.90.51.51 </a>
              <a href='mailto:fcihoudan@fcimmo.com' style={{color: 'white', textDecoration: "underline"}}>fcihoudan@fcimmo.com</a>
              <span>17 Grande rue</span>
              <span>78550 HOUDAN</span>
            </span>
          </Col>
        </Row>

        <Row style={agenceRow}>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginTop: '1%', marginBottom: '1%'}}>
            <img src={agenceMaule} style={{width: '100%', height: '100%'}} fluid />
          </Col>
          <Col xs='12' lg='7' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
            <span style={{fontSize:'calc(1em + 0.5vw)', fontWeight: "bold"}}>FCI MAULE Transactions</span>
            <span style={{fontSize: 'calc(0.7em + 0.2vw)', textAlign: 'justify', marginTop: 5}}>
              Au centre de cette ville animée aux portes de Paris par l’A13, l’A14, venez découvrir le côté pratique
              de Maule. Tout est proche et les accès sont faciles de Maule et des nombreux charmants villages
              environnants.
              Françoise Combes maitrise parfaitement le secteur
              et ils vous offriront un service professionnel. L’achat de votre habitation sera un plaisir. Votre
              accompagnement chez FCI est réel et construit.
              Nos clients sont tous satisfaits, les avis clients sont notre vitrine la plus convaincante.
              Vieilles pierres ou ambiance contemporaine, notre panel est large et vous trouverez assurément
              votre bonheur avec nous.
            </span>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginBottom: '1%', marginTop: '1%'}}>
            <span style={contactInfo}>
              <a href="tel:0134750808" style={{color: 'white'}}>Tél. : 01.34.75.08.08 </a>
              <a href='mailto:contact@fcisyndic.com' style={{color: 'white', textDecoration: "underline"}}>maule@fcimmo.com</a>
              <span>2 place de la mairie</span>
              <span>78580 MAULE</span>
            </span>
          </Col>
        </Row>

        <Row style={agenceRow}>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginTop: '1%', marginBottom: '1%'}}>
            <img src={agenceSyndic} style={{width: '100%', height: '100%'}} fluid />
          </Col>
          <Col xs='12' lg='7' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
            <span style={{fontSize:'calc(1em + 0.5vw)', fontWeight: "bold"}}>FCI Syndic</span>
            <span style={{fontSize: 'calc(0.7em + 0.2vw)', textAlign: 'justify', marginTop: 5}}>
              Enfin un Syndic à l’écoute, disponible, rapide.
              Les comptes sont transparents, toute information est à votre disposition.
              Vous aurez toujours réponses à vos questions.
              Jérémy Debreux, Salomé Retailleau, Olivier Avot et Jennifer Cunière forment une équipe professionnelle, compétente, rapide et efficace.
              Nos process et méthodes sont appréciés par tous nos copropriétaires.
              Petites ou grandes, vos copropriétés seront gérées parfaitement.
              Nous sommes disposés à vous rencontrer pour vous aider à changer de Syndic, et étudier un contrat
              au plus proche de votre attente.
            </span>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginBottom: '1%', marginTop: '1%'}}>
            <span style={contactInfo}>
              <a href="tel:0186229696" style={{color: 'white'}}>Tél. : 01.86.22.96.96 </a>
              <a href='mailto:contact@fcisyndic.com' style={{color: 'white', textDecoration: "underline"}}>contact@fcisyndic.com</a>
              <span>53 avenue Mozart</span>
              <span>75016 PARIS</span>
            </span>
          </Col>
        </Row>

        <Row style={agenceRow}>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginTop: '1%', marginBottom: '1%'}}>
            <img src={agenceGestion} style={{width: '100%', height: '100%'}} fluid />
          </Col>
          <Col xs='12' lg='7' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
            <span style={{fontSize:'calc(1em + 0.5vw)', fontWeight: "bold"}}>FCI Gestion</span>
            <span style={{fontSize: 'calc(0.7em + 0.2vw)', textAlign: 'justify', marginTop: 5}}>
              Murièle Roques et Olivier Avot, prendront en charge la location et la gestion complète de vos
              propriétés et appartements.
              Vous cherchez la tranquillité et la sérénité ?
              Notre service professionnel vous les garantit.
              Disponibilité, sérieux, suivi et conseils, sont les atouts que nous vous proposons.
              Notre service gestion locative fonctionne parfaitement.
              N’hésitez pas à vous renseigner.
              Nous gérerons efficacement vos biens à Paris, petite et grande couronne, ainsi que dans l’ouest
              parisien (78,28,27)
            </span>
          </Col>
          <Col xs='12' lg='2' style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column', marginBottom: '1%', marginTop: '1%'}}>
            <span style={contactInfo}>
              <a href="tel:0178905151" style={{color: 'white'}}>Tél. : 01.78.90.51.51</a>
              <a href='mailto:contact@fcimmo.com' style={{color: 'white', textDecoration: "underline"}}>contact@fcimmo.com</a>
              <span>17 Grande rue</span>
              <span>78550 HOUDAN</span>
            </span>
          </Col>
        </Row>

      </Container>
      <Footer/>
</motion.div>
  );
}

var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  height:'100%',
  backgroundImage: `url(${photo})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  maxWidth: '100%',
  paddingBottom: 10,
  padding: 0,
}



var contactInfo = {
  width: "100%",
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  justifySelf: 'center',
  alignSelf: 'center',
  flexDirection:'column',
  border: '2px solid #206A37',
  backgroundColor: "#206A37",
  color: "white",
  fontSize: 13,
  marginTop: 15,
 
  
}

var agenceRow = {
  backgroundColor: 'rgba(255,255,255, 0.9)',  
  borderRadius: 10, 
  width: '85%', 
  justifySelf: 'center', 
  alignSelf: 'center', 
  justifyContent:'space-between', 
  alignItems: 'center', 
  marginTop: 10,
  marginBottom: 10
}

export default NosAgences;