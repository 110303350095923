import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Input, PopoverBody, Button,  } from 'reactstrap';
import photo from './images/PageSyndic.png'
import { Link } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import {connect} from 'react-redux'
import Footer from './Footer'
import { UncontrolledCarousel } from 'reactstrap';
import { motion } from 'framer-motion'
import NavBar from "./NavBar"




function Syndic(props) {

    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
  
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);
  
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    const [logInMessage, setLogInMessage] = useState([])
  
    const [logInAccepted, setLogInAccepted] = useState(false)
  
    const [userGenre, setUserGenre] = useState('')
    const [usersName, setUsersName] = useState('')
  
    const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))
  
    var handleLogout = () => {
        localStorage.removeItem('usersToken')
        setLogInAccepted(false)
    }
    
        
    useEffect(async() => {
  
  
        if(userFoundFromToken != null){
  
            
        var rawData = await fetch('/sendToken', {
            method: 'POST',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `tokenFromFront=${userFoundFromToken}`
      }); 
      
          var data = await rawData.json()
          
          if(data.result === true){
            setLogInAccepted(true)
            setUsersName(data.user.prenom)
            setUserGenre(data.user.genre)
          }
  
        }
  
  
         
      },[]);
  
  
  

    const items = [
      {
        src: "https://imgur.com/BRmsUh4.png"
      },
      {
        src: "https://i.imgur.com/DR4pgSf.png"
      },
      {
        src: "https://i.imgur.com/2IE31dw.png"
      },
      {
        src: "https://i.imgur.com/JcAdNjn.png"
      },
      {
        src: "https://i.imgur.com/OD2F3cF.png"
      }
    ]
   
    
  
    return (
<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{opacity: 0 }}
>

        <Container style={BackgroundImage}>
    
        <NavBar/>

    
          <Row style={descRow}>

              <Col xs='12' lg='6' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', }}>

                <UncontrolledCarousel items={items} />

              </Col>
    
          </Row>

          <Row style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 'calc(1em + 3vw)'}}>
              <Col xs='12' lg='6' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0}}>
                  <Button style={{width: '100%', backgroundColor: '#206A37'}}><Link to='/estimer' style={{color: 'white'}} onClick={()=>props.onContactClick('Je veux changer de Syndic.')}>Demander un devis</Link></Button>
              </Col>
          </Row>

        </Container>
        <Footer/>

</motion.div>
    );


  }


  function mapDispatchToProps(dispatch) {
    return {
        onContactClick: function(reason) {
            dispatch( {type: 'addReason', whatReason: reason})
        }
    }
}
  var carouselImages = {
      width: 'auto',
      display: 'flex',
      justifySelf: 'center',
      justifyItems: 'center',
      alignSelf: 'center', 
      alignItems: 'center'
  }
  
  var BackgroundImage = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height:'auto',
    backgroundImage: `url(${photo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    maxWidth: '100%',
    padding: 0,
  }

  
  var navBarRow ={
    backgroundColor: 'white',
    height: 'auto',
    diplay: 'flex',
    flexDirection: 'row',
    justifySelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1vw',
    opacity: '90%'
  }
  
  var descRow = {
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'calc(1em + 3vw)',
    marginBottom: 'calc(1em + 1vw)',
    width: '100%'
  }
  


export default connect (
    null,
    mapDispatchToProps
) (Syndic);