import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import NosAgences from './NosAgences';
import Contact from './Contact';
import Estimer from './Estimer'
import Recherche from './Recherche';
import Map from './Map'
import RechercheAvance from './RechercheAvance'
import Resultats from './Resultats'
import Outils from './Outils'
import MesRecherches from './MesRecherches'
import Wishlist from './Wishlist'
import QuiSommesNous from './QuiSommesNous'
import HomePage2 from './HomePage2'
import CreationDeCompte from './CreationDeCompte'
import ServicesSyndic from './ServicesSyndic'
import PageLaisserAvis from './PageLaisserAvis'
import BiensVendus from './BiensVendues'
import reason from './reducers/reason'
import token from './reducers/token'
import filtresDeRecherche from "./reducers/filtresDeRecherche"
import mapCities from "./reducers/mapCities"
import typeTransaction from "./reducers/typeTransaction"
import biensLength from "./reducers/biensLength"
import bienToDisplay from "./reducers/bienToDisplay"
import trierPar from './reducers/trierPar'
import {Provider} from 'react-redux'
import {createStore, combineReducers} from 'redux';
import PageBien from './PageBien'
import Car from './car'
import {AnimatePresence} from 'framer-motion'
import History from './History'
import Honoraires from './Honoraires';


const store = createStore(combineReducers({reason, token, typeTransaction, mapCities, filtresDeRecherche, biensLength, bienToDisplay, trierPar}))

function App() {



  
  return (

    <Provider store={store}>
    
      <HashRouter  history={History}>
        <AnimatePresence>
          <Switch>
            <Route path="/" exact component={HomePage2}/>
            <Route path="/nosagences" exact component={NosAgences}/>
            <Route path="/contact" exact component={Contact}/>
            <Route path="/estimer" exact component={Estimer}/>
            <Route path="/recherche" exact component={Recherche}/>
            <Route path="/map" exact component={Map}/>
            <Route path="/rechercheavances" exact component={RechercheAvance}/>
            <Route path="/resultats" exact component={Resultats}/>
            <Route path="/outils" exact component={Outils}/>
            <Route path="/mesrecherches" exact component={MesRecherches}/>
            <Route path="/wishlist" exact component={Wishlist}/>
            <Route path="/quisommesnous" exact component={QuiSommesNous}/>
            <Route path="/creationdecompte" exact component={CreationDeCompte}/>
            <Route path="/syndic" exact component={ServicesSyndic}/>
            <Route path="/maptest" exact component={Map}/>
            <Route path="/bien" exact component={PageBien}/>
            <Route path="/honoraires" exact component={Honoraires}/>
            <Route path="/laisserAvis" exact component={PageLaisserAvis}/>
            <Route path="/testing" exact component={Car}/>
            <Route path="/biensvendus" exact component={BiensVendus}/>
          </Switch>
        </AnimatePresence>
      </HashRouter >

    </Provider>
  );
}

export default App;
