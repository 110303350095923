import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Label,
} from "reactstrap";
import photo from "./images/Recherche.png";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Divider } from "antd";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { motion } from "framer-motion";
import { InputNumber } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import NavBar from "./NavBar";



function Recherche(props) {
  const history = useHistory();

  function onChangeBudgetMin(value) {
    setBudgetMin(value);
  }
  function onChangeBudgetMax(value) {
    setBudgetMax(value);
  }

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [userFoundFromToken, setUserFoundFromToken] = useState(
    localStorage.getItem("usersToken")
  );

  const [agencyParis, setAgencyParis] = useState(false)
  const [agencyHoudan, setAgencyHoudan] = useState(false)
  const [agencyMaule, setAgencyMaule] = useState(false)

  const [plusDeType, setPlusDeType] = useState(false)

  const [budgetMin, setBudgetMin] = useState("");
  const [budgetMax, setBudgetMax] = useState("");
  const [appartement, setAppartement] = useState(false);
  const [maison, setMaison] = useState(false);
  const [loft, setLoft] = useState(false);
  const [bureau, setBureau] = useState(false);
  const [hP, setHP] = useState(false);
  const [parking, setParking] = useState(false);
  const [battiment, setBattiment] = useState(false);
  const [fondCommerce, setFondsCommerce] = useState(false);
  const [terrain, setTerrain] = useState(false);
  const [grange, setGrange] = useState(false);
  const [localCommercial, setLocalCommercial] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mapCitiesFromRedux, setMapCitiesFromRedux] = useState(
    props.citiesFromMapsToDisplay
  );
  const [loading, setLoading] = useState(false);
  const [citiesTableau, setCitiesTableau] = useState([]);

  async function handleValider() {
    setCitiesTableau(citiesTableau.filter((e) => e.city !== undefined));

    setLoading(true);

    var noEndroitOrMap = false;
    var noBudget = false;
    var noTypeDeBien = false;

    var noBiensSelected = false;
    if (
      appartement === false &&
      maison === false &&
      loft === false &&
      bureau === false &&
      hP === false &&
      battiment === false &&
      parking === false &&
      fondCommerce === false &&
      terrain === false &&
      grange === false &&
      localCommercial === false
    ) {
      noBiensSelected = true;
    }

    var agencies = []
    if(agencyParis === true){
      agencies.push("Paris")
    }
    if(agencyHoudan === true){
      agencies.push("Houdan")
    }
    if(agencyMaule === true){
      agencies.push("Maule")
    }

    var allFilters = {
      agencies: agencies,
      citiesTableau: citiesTableau,
      citiesMap: mapCitiesFromRedux,
      typeDeTransaction: localStorage.getItem('transaction'),
      budgetMin: budgetMin,
      budgetMax: budgetMax,
      appartement: appartement,
      maison: maison,
      loft: loft,
      bureau: bureau,
      hotelP: hP,
      immeuble: battiment,
      parking: parking,
      fondCommerce: fondCommerce,
      terrain: terrain,
      grange: grange,
      localCommercial: localCommercial,
      noBiensSelected: noBiensSelected,
    };
    // props.onFiltersValidate(allFilters);

    if (budgetMin === "" && budgetMax === "") {
      noBudget = true;
    }
    if (noBiensSelected === true) {
      noTypeDeBien = true;
    }

    if (noEndroitOrMap === true && noBudget === true && noTypeDeBien === true) {
    } else if (userFoundFromToken != null) {
      await fetch("/addFiltersInRecherches", {
        method: "POST",
        headers: { "Content-Type": "application/json", Origin: "*" },
        body: JSON.stringify({
          tokenFromFront: userFoundFromToken,
          filtersFromFront: allFilters,
        }),
      }).catch((error) =>
        console.log("Authorization failed : " + error.message)
      );
    }

    await history.push("/resultats");
  }

  var typeStyleNotSelected = {
    marginRight: 2,
    marginLeft: 2,
    border: "1px dashed #206A37",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    color: "#206A37",
  };
  var typeStyleSelected = {
    marginRight: 2,
    marginLeft: 2,
    backgroundColor: "#206A37",
    color: "white",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
  };

  var agencyStyleNotSelected = {
    width: 120,
    marginRight: 2,
    marginLeft: 2,
    border: "1px dashed #206A37",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    color: "#206A37",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",

  };

  var agencyStyleSelected = {
    width: 120,
    marginRight: 2,
    marginLeft: 2,
    backgroundColor: "#206A37",
    color: "white",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };

  var styleOfParis = agencyStyleNotSelected
  var styleOfHoudan = agencyStyleNotSelected
  var styleOfMaule = agencyStyleNotSelected


  var styleOfApp = typeStyleNotSelected;
  var styleOfMaison = typeStyleNotSelected;
  var styleOfLoft = typeStyleNotSelected;
  var styleOfGrange = typeStyleNotSelected;
  var styleOfParking = typeStyleNotSelected;
  var styleOfImmeuble = typeStyleNotSelected;
  var styleOfFonds = typeStyleNotSelected;
  var styleOfTerrain = typeStyleNotSelected;
  var styleOfLocalCommercial = typeStyleNotSelected;

  if(agencyParis === true){
    styleOfParis = agencyStyleSelected
  }

  if(agencyHoudan === true){
    styleOfHoudan = agencyStyleSelected
  }

  if(agencyMaule === true){
    styleOfMaule = agencyStyleSelected
  }

  if (appartement === true) {
    styleOfApp = typeStyleSelected;
  }
  if (maison === true) {
    styleOfMaison = typeStyleSelected;
  }
  if (loft === true) {
    styleOfLoft = typeStyleSelected;
  }
  if (grange === true) {
    styleOfGrange = typeStyleSelected;
  }
  if (parking === true) {
    styleOfParking = typeStyleSelected;
  }
  if (battiment === true) {
    styleOfImmeuble = typeStyleSelected;
  }
  if (fondCommerce === true) {
    styleOfFonds = typeStyleSelected;
  }
  if (terrain === true) {
    styleOfTerrain = typeStyleSelected;
  }
  if (localCommercial === true) {
    styleOfLocalCommercial = typeStyleSelected;
  }

  var inputTerrain;
  var inputGrange;
  var inputFondsDeCommerce;

  if (localStorage.getItem("transaction") === "Acheter") {
    inputTerrain = (
      <span style={styleOfTerrain} onClick={() => setTerrain(!terrain)}>
        Terrain
      </span>
    );

    inputGrange = (
      <span style={styleOfGrange} onClick={() => setGrange(!grange)}>
        Grange
      </span>
    );

    inputFondsDeCommerce = (
      <span
        style={styleOfFonds}
        onClick={() => setFondsCommerce(!fondCommerce)}
      >
        Fonds de Commerce
      </span>
    );
  }

  var basicInputs = (
    <span
    style={{
      width: "100%",
      marginBottom: 30,
      marginTop: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <Row
      style={{
        diplay: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <span style={styleOfApp} onClick={() => setAppartement(!appartement)}>
        Appartement
      </span>
      <span style={styleOfMaison} onClick={() => setMaison(!maison)}>
        Maison
      </span>
      {inputTerrain}
      <span>
        <Button style={{backgroundColor: "#206A37"}} onClick={()=>setPlusDeType(!plusDeType)}>+</Button>
      </span>
    </Row>
  </span>
  )

  var allInputs = (
    <span
      style={{
        width: "100%",
        marginBottom: 30,
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Row
        style={{
          diplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <span style={styleOfApp} onClick={() => setAppartement(!appartement)}>
          Appartement
        </span>
        <span style={styleOfMaison} onClick={() => setMaison(!maison)}>
          Maison
        </span>
        {inputTerrain}
        <span>
          <Button style={{backgroundColor: "#206A37"}} onClick={()=>setPlusDeType(!plusDeType)}>-</Button>
        </span>
        
      </Row>

      <Row
        style={{
          diplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: 5,
        }}
      >
        <span
          style={styleOfLocalCommercial}
          onClick={() => setLocalCommercial(!localCommercial)}
        >
          Local Commercial
        </span>
        <span style={styleOfLoft} onClick={() => setLoft(!loft)}>
          Loft
        </span>
        <span style={styleOfParking} onClick={() => setParking(!parking)}>
          Parking / Box
        </span>
        <span style={styleOfImmeuble} onClick={() => setBattiment(!battiment)}>
          Immeuble
        </span>
        {inputFondsDeCommerce}
        {inputGrange}
      </Row>
    </span>
  );

  if (props.typeTransactionToDisplay === "Viager") {
    allInputs = (
      <Row
        style={{
          diplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginBottom: 30,
          marginTop: 10,
        }}
      >
        <Col xs="12" lg="3" style={styleColRadio}>
          <Label check style={radioButtonsStyle}>
            <Input
              type="checkbox"
              name="Appartement"
              checked={appartement}
              onChange={(e) => setAppartement(e.target.checked)}
            />
            Appartement
          </Label>
        </Col>
        <Col xs="12" lg="3" style={styleColRadio}>
          <Label check style={radioButtonsStyle}>
            <Input
              type="checkbox"
              name="Maison"
              checked={maison}
              onChange={(e) => setMaison(e.target.checked)}
            />
            Maison
          </Label>
        </Col>
        <Col xs="12" lg="3" style={styleColRadio}>
          {/* <Label check style={radioButtonsStyle}> */}
          <Input
            type="checkbox"
            name="Loft"
            checked={loft}
            onChange={(e) => setLoft(e.target.checked)}
          />
          <span>Loft</span>
          {/* </Label> */}
        </Col>
      </Row>
    );
  }

  var validationLink = (
    <span
      to="/resultats"
      onClick={() => handleValider()}
      style={{
        color: "white",
        fontSize: "calc(1em + 0.6vw)",
        fontWeight: "bold",
      }}
    >
      Valider
    </span>
  );

  if (
    appartement === false &&
    maison === false &&
    loft === false &&
    bureau === false &&
    parking === false &&
    battiment === false &&
    fondCommerce === false &&
    terrain === false &&
    grange === false &&
    localCommercial === false
  ) {
    validationLink = (
      <span
        onClick={() => setErrorMessage("Séléctionnez au moins un type de bien")}
        style={{
          color: "white",
          fontSize: "calc(1em + 0.6vw)",
          fontWeight: "bold",
        }}
      >
        Valider
      </span>
    );
  }


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container style={BackgroundImage}>
        <NavBar />

        <Col xs="12" lg="6" style={firstRow}>
          <Row>
            <Col
              xs="12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                alignSelf: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span style={spanContactezNous}>
                {localStorage.getItem('transaction').toUpperCase()}
              </span>
            </Col>
          </Row>

          <Divider
            style={{
              width: "100%",
              backgroundColor: "#206A37",
              height: "0.7px",
              margin: 0,
            }}
          />

          <Row style={{ width: "100%", marginTop: 10 }}>
            <Col
              xs="12"
              lg="4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 0 5px 0",
              }}
            >
              <Button
                size="sm"
                style={{
                  backgroundColor: "#206A37",
                  fontSize: "calc(0.5em + 0.4vw)",
                }}
              >
                <Link style={{ color: "white" }} to="/mesrecherches">
                  <FontAwesomeIcon
                    icon={faHistory}
                    style={{ color: "white", margin: "2px" }}
                  />
                  Mes dernières recherches
                </Link>
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              justifySelf: "center",
              alignSelf: "center",
              width: "100%",
              marginTop: "15px",
              flexDirection: "column"
            }}
          > 
          <div>
            <span style={{ color: "#206A37", textAlign: "center", fontWeight: "bold"}}>Quelle agence ?</span>
          </div>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15, marginBottom: 25}}>
            <span
              style={styleOfParis}
              onClick={() => setAgencyParis(!agencyParis)}
            >
              <span style={{fontSize: 17,fontWeight: "bold"}}>PARIS</span>
              <span style={{fontWeight: "bold"}}>( 75 )</span>
            </span>
            <span
              style={styleOfHoudan}
              onClick={() => setAgencyHoudan(!agencyHoudan)}
            >
              <span style={{fontSize: 17,fontWeight: "bold"}}>HOUDAN</span>
              <span style={{fontWeight: "bold"}}>( 78-28-27 )</span>
            </span>
            <span
              style={styleOfMaule}
              onClick={() => setAgencyMaule(!agencyMaule)}
            >
              <span style={{fontSize: 17,fontWeight: "bold"}}>MAULE</span>
              <span style={{fontWeight: "bold"}}>( 78 )</span>
            </span>
          </div>
          </Row>

          {/* {mapButton} */}

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              justifySelf: "center",
              alignSelf: "center",
              width: "100%",
              marginTop: "15px",
            }}
          >
            <Col
              xs="12"
              lg="3"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                alignSelf: "center",
                width: "100%",
              }}
            >
              <span style={{ color: "#206A37", textAlign: "center", fontWeight: "bold" }}>
                Votre budget Min?
              </span>
              <InputNumber
                size="middle"
                defaultValue={1000}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                value={budgetMin}
                onChange={onChangeBudgetMin}
                style={{ width: "150px", border: "solid 0.5px #206A37" }}
              />
            </Col>
            <Col
              xs="12"
              lg="3"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                justifySelf: "center",
                alignSelf: "center",
                width: "100%",
              }}
            >
              <span style={{ color: "#206A37", textAlign: "center" , fontWeight: "bold"}}>
                Votre budget Max?
              </span>
              <InputNumber
                size="middle"
                defaultValue={1000}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                value={budgetMax}
                onChange={onChangeBudgetMax}
                style={{ width: "150px", border: "solid 0.5px #206A37" }}
              />
            </Col>
          </Row>

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#206A37",
              marginTop: 30,
            }}
          >
            <span style={{ color: "#206A37", textAlign: "center" , fontWeight: "bold"}}>Type de bien :</span>
          </Row>

          <Row style={{ color: "red", fontWeight: "bold", marginTop: 5 }}>
            {errorMessage}
          </Row>
          {plusDeType?(allInputs):(basicInputs)}

          {/* <Row style={{width: '100%', marginTop:'1vw', marginBottom: '1vw', display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Col xs='12' lg='4'  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 0 5px 0'}}>
              <Button size="sm" style={{backgroundColor: '#206A37',fontSize: 'calc(0.5em + 0.4vw)'}}><Link style={{color: 'white'}} to="/rechercheavances"><FontAwesomeIcon icon={faPlus} style={{color: 'white', margin: '2px'}}/>Recherche Avancé</Link></Button>
            </Col>
          </Row> */}

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <Button style={secondRow}>{validationLink}</Button>
          </Row>
        </Col>
      </Container>
      <Footer />
    </motion.div>
  );
}

// function mapDispatchToProps(dispatch) {
//   return {
//     onFiltersValidate: function (filters) {
//       dispatch({ type: "addFilters", thisFilters: filters });
//     },
//   };
// }

function mapStateToProps(state) {
  return {
    typeTransactionToDisplay: state.typeTransaction,
    citiesFromMapsToDisplay: state.mapCities,
  };
}

var BackgroundImage = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  height: "auto",
  backgroundImage: `url(${photo})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  maxWidth: "100%",
  padding: 0,
};

var firstRow = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255,255,255, 0.9)",
  justifySelf: "center",
  alignSelf: "center",
  marginTop: 50,
  borderRadius: "10px",
  border: 0,
  marginBottom: 50,
};

var secondRow = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#206A37",
  justifySelf: "center",
  alignSelf: "center",
  borderRadius: "10px",
  paddingBottom: 10,
  paddingTop: "7px",
  border: 0,
  marginTop: "7px",
};

var spanContactezNous = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  color: "#206A37",
  fontSize: "calc(1em + 0.6vw)",
  textAlign: "center",
  fontWeight: "bold",
};

var radioButtonsStyle = {
  textAlign: "center",
  color: "#206A37",
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  fontSize: 12,
  alignItems: "center",
};

var styleColRadio = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  border: "1px solid #206A37",
  borderRadius: 10,
  padding: 5,
  marginRight: 10,
};

export default connect(mapStateToProps)(Recherche);
