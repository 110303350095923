import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Spinner, Button } from "reactstrap";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'antd';

function LocationMaison(props) {
  Geocode.setApiKey("AIzaSyBfHwT25FD-yBY7aShuI9OfqLKc_QWHzFs");
  Geocode.setLanguage("fr");
  Geocode.setRegion("fr");

  const [locationMaison, setLocationMaison] = useState([]);
  const [filtersFromRedux, setFilterFromRedux] = useState(
    props.filtersToDisplay
  );
  const [loaded, setLoaded] = useState(false);

  const trier = props.trierParToDisplay;

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  function inside(point, vs) {
    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  var pasDeBiens;

  useEffect(async () => {
    var toutBiens = [];
    var toutLocation = [];

    var lcMaison = [];
    var lcMaisonFilteredByVille = [];
    var lcMaisonFilteredByMap = [];
    var lcMaisonFilteredByBudget = [];
    var filteredBudgetForMap = false;

    var iconv = require("iconv-lite");

    var myHeaders = new Headers();
    myHeaders.append("Cookie", "MOLSESSION=S1839|YE+Vr|YE+Vr");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var getXml = await fetch("/getXMLfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Origin: "*",
      },
      body: JSON.stringify({
        emxaple: "hey",
      }),
    })
      .then((response) => response.json())
      .then((obj) => {
        toutBiens = obj.jsonOb.LISTEPA.BIEN;

        for (let i = 0; i < toutBiens.length; i++) {
          var keys = Object.keys(toutBiens[i]);
          for (let j = 0; j < keys.length; j++) {
            if (keys[j] === "LOCATION") {
              toutLocation.push(toutBiens[i]);
            }
          }
        }

        for (let i = 0; i < toutLocation.length; i++) {
          var keys = Object.keys(toutLocation[i]);
          var includesMaison = keys.includes("MAISON");
          if (includesMaison === true) {
            lcMaison.push(toutLocation[i]);
          }
        }
        setLocationMaison(lcMaison);

        var biensId = [];
        let appFilteredByAgency = [];


        if(localStorage.getItem('Agency') != ""){
          for (let i = 0; i<lcMaison.length; i++){
            if(localStorage.getItem('Agency') == "Paris"){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "PARIS") {
                appFilteredByAgency.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
            if(localStorage.getItem('Agency') == "Houdan"){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "HOUDAN") {
                appFilteredByAgency.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
            if(localStorage.getItem('Agency') == "Maule"){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "MAULE") {
                appFilteredByAgency.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
          }
          setLocationMaison(appFilteredByAgency)
        }

        let filteredBudget = [];
        
        if(localStorage.getItem("Budget Min") != "" || localStorage.getItem("Budget Max") != ""){
          for (let i = 0; i < lcMaison.length; i++) {
            if(localStorage.getItem("Budget Min") != "" && localStorage.getItem("Budget Max") == ""){
                if(lcMaison[i].LOCATION.LOYER >= localStorage.getItem("Budget Min")){
                  filteredBudget.push(lcMaison[i]);
                }
              }
            if(localStorage.getItem("Budget Max") != "" && localStorage.getItem("Budget Min") == ""){
              if(lcMaison[i].LOCATION.LOYER <= localStorage.getItem("Budget Max")){
                filteredBudget.push(lcMaison[i]);
              }
            }
            if(localStorage.getItem("Budget Max") != "" && localStorage.getItem("Budget Min") != "" ){
              if(lcMaison[i].LOCATION.LOYER <= localStorage.getItem("Budget Max") && lcMaison[i].LOCATION.LOYER >= localStorage.getItem("Budget Min")){
                filteredBudget.push(lcMaison[i]);
              }
            }
          }
          setLocationMaison(filteredBudget);
        }

        
        if( filtersFromRedux.agencies.length > 0 ){
          for (let i = 0; i < lcMaison.length; i++) {
            if( filtersFromRedux.agencies.includes("Paris") === true ){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "PARIS") {
                lcMaisonFilteredByVille.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
            if( filtersFromRedux.agencies.includes("Houdan") === true ){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "HOUDAN") {
                lcMaisonFilteredByVille.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
            if( filtersFromRedux.agencies.includes("Maule") === true ){
              if (lcMaison[i].AGENCE.AGENCE_VILLE === "MAULE") {
                lcMaisonFilteredByVille.push(lcMaison[i]);
                biensId.push(lcMaison[i].INFO_GENERALES.AFF_ID);
              }
            }
          }
          setLocationMaison(lcMaisonFilteredByVille);
        }

        if (filteredBudgetForMap === false) {
          var tableauABoucler = lcMaisonFilteredByVille;

          if (lcMaisonFilteredByMap.length != 0) {
            tableauABoucler = lcMaisonFilteredByMap;
          }
          if (
            lcMaisonFilteredByVille.length === 0 &&
            lcMaisonFilteredByMap.length === 0
          ) {
            tableauABoucler = lcMaison;
          }

          if (
            filtersFromRedux.budgetMax != "" &&
            filtersFromRedux.budgetMin != ""
          ) {
            for (let i = 0; i < tableauABoucler.length; i++) {
              if (
                tableauABoucler[i].LOCATION.LOYER <=
                  filtersFromRedux.budgetMax &&
                tableauABoucler[i].LOCATION.LOYER >= filtersFromRedux.budgetMin
              ) {
                lcMaisonFilteredByBudget.push(tableauABoucler[i]);
              }
            }
            setLocationMaison(lcMaisonFilteredByBudget);
          }
          if (
            filtersFromRedux.budgetMax != "" &&
            filtersFromRedux.budgetMin === ""
          ) {
            for (let i = 0; i < tableauABoucler.length; i++) {
              if (
                tableauABoucler[i].LOCATION.LOYER <= filtersFromRedux.budgetMax
              ) {
                lcMaisonFilteredByBudget.push(tableauABoucler[i]);
              }
            }
            setLocationMaison(lcMaisonFilteredByBudget);
          }
          if (
            filtersFromRedux.budgetMin != "" &&
            filtersFromRedux.budgetMax === ""
          ) {
            for (let i = 0; i < tableauABoucler.length; i++) {
              if (
                tableauABoucler[i].LOCATION.LOYER >= filtersFromRedux.budgetMin
              ) {
                lcMaisonFilteredByBudget.push(tableauABoucler[i]);
              }
            }
            setLocationMaison(lcMaisonFilteredByBudget);
          }
        }
      })
      .catch((error) => console.log("error", error));

    setTimeout(() => {
      setLoaded(true);
    }, 900);
  }, []);

  if (loaded === false) {
    pasDeBiens = (
      <Spinner style={{ marginTop: 30, marginBottom: 30 }} color="light" />
    );
  }

  if (loaded === true && locationMaison.length === 0) {
    pasDeBiens = (
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          Il n'y a pas des biens correspondant à votre recherche
        </span>
        <Button style={{ backgroundColor: "#206A37" }}>
          <Link to="/recherche" style={{ color: "white" }}>
            Revenir à la page Recherche
          </Link>
        </Button>
      </span>
    );
  }

  if (trier === "Prix croissant") {
    locationMaison.sort(
      (a, b) => parseFloat(a.LOCATION.LOYER) - parseFloat(b.LOCATION.LOYER)
    );
  }

  if (trier === "Prix décroissant") {
    locationMaison.sort(
      (a, b) => parseFloat(b.LOCATION.LOYER) - parseFloat(a.LOCATION.LOYER)
    );
  }

  var Decorators = (
    <button
      style={{ position: "CenterLeft", padding: 20 }}
      onClick={props.previousSlide}
    >
      Previous Slide
    </button>
  );

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        flexDirection: "column",
      }}
    >
      <span
        style={{
          display: "flex",
          color: "white",
          alignSelf: "flex-start",
          fontWeight: "bold",
        }}
      >
        {" "}
        MAISONS :{" "}
      </span>
      <Divider
        style={{
          color: "white",
          height: 10,
          borderTopColor: "white",
          fontWeight: "normal",
          margin: 5,
        }}
      />
      {pasDeBiens}
      {locationMaison.map(function (bien, i) {
        var bienUrl = `/bien?id=${bien.INFO_GENERALES.AFF_NUM}`;
        var bienImage = bien.IMAGES.IMG;
        var caption100 = bien.COMMENTAIRES.FR.substring(0, 200) + " ...";

        if (bien.IMAGES === "" || bien.IMAGES == undefined) {
          bienImage = [
            "https://www.grandparquet.com/wp-content/uploads/2016/02/pas-de-photo.png",
          ];
        }
        if (typeof bien.IMAGES.IMG == "string") {
          bienImage = [bien.IMAGES.IMG];
        }

        return (
            <Row style={bigRowStyle}>
              <Col xs="12" lg="6" style={imageColStyle}>
              <Carousel showThumbs={false} centerMode={true} centerSlidePercentage={100} dynamicHeight={false} style={{height: 500, display: "flex", justifyContent: "center", alignItems: "center", justifySelf: "center", alignSelf: "center", backgroundColor: "transparent"}}>
                {bienImage.map(function (image, i) {
                  return (
                    <div to={bienUrl} key={i} onClick={() => props.onBienClick(bien)} style={{width: "auto", height: 500, cursor: "zoom-in",   backgroundImage: "linear-gradient(to right top, #569e67, #86b57a, #b1cb92, #d9e3ae, #fffbce)"}}>
                      <Image src={image} style={{cursor: "zoom-in"}} width="auto" height="100%"/>
                    </div>
                  );
                })}
              </Carousel>
              </Col>
              <Col xs="12" lg="6" style={bigCol}>
                <Link to={bienUrl} key={i} onClick={() => props.onBienClick(bien)} style={infosRow}>
                  <Badge
                    style={{
                      backgroundColor: "#206A37",
                      fontSize: "calc(0.5em + 0.5vw)",
                    }}
                  >
                    {numberWithSpaces(bien.LOCATION.LOYER)} €
                  </Badge>
                  <strong style={blackFont}>Maison</strong>
                  <strong style={blackFont}>
                    {" "}
                    {bien.MAISON.NBRE_PIECES} p - {bien.MAISON.NBRE_CHAMBRES} ch
                    - {bien.MAISON.SURFACE_HABITABLE} m²{" "}
                  </strong>
                  <span style={blackFont}>{bien.LOCALISATION.VILLE}</span>
                  <span style={blackFont}>{bien.LOCALISATION.CODE_POSTAL}</span>
                </Link>
                <Link
                  to={bienUrl} key={i} onClick={() => props.onBienClick(bien)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "98%",
                    paddingLeft: 15,
                  }}
                >
                  <span
                    style={{
                      fontSize: "calc(0.5em + 0.4vw)",
                      color: "#206A37",
                    }}
                  >
                    {caption100}
                  </span>
                </Link>
              </Col>
            </Row>
        );
      })}
    </Container>
  );
}

var bigRowStyle = {
  backgroundColor: "rgba(255,255,255, 0.8)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width:"100%",
  margin: "0px",
  marginTop: 10,
  marginBottom: 10,
};

var blackFont = {
  color: "black",
};

var bigCol = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "80%",
  padding: 15,
  fontSize: 25
};

var infosRow = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginBottom: 10,
  width: "100%",
  paddingLeft: 15,
};

var imageColStyle = {
  backgroundColor: "black",
  height: 500,
  width: 300,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
};

function mapDispatchToProps(dispatch) {
  return {
    onCheckLength: function (length) {
      dispatch({ type: "addLength", thisLength: length });
    },
    onBienClick: function (bienInfo) {
      dispatch({ type: "addBienData", thisData: bienInfo });
    },
  };
}

function mapStateToProps(state) {
  return {
    filtersToDisplay: state.filtresDeRecherche,
    trierParToDisplay: state.trierPar,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMaison);
