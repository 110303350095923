import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Input,
  PopoverBody,
  Button,
  Table,
} from "reactstrap";
import photo from "./images/HonorairesBI.jpg";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { motion } from "framer-motion";
import NavBar from "./NavBar";

function Honoraires() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Container style={BackgroundImage}>
        <NavBar />

        <Row style={descRow}>
          <span style={titleStyle}>Honoraires de l'agence F.C.I</span>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              alignSelf: "flex-end",
            }}
          >
            <span style={subTitleStyle}>(T.V.A 20% incluse)</span>
            <span style={subTitleStyle}>
              TARIFS: à compter du 1er Octobre 2017
            </span>
          </div>
          <Table bordered style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th>Montant de la transaction</th>
                <th>Rénumération</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Jusqu'à 75.000€</td>
                <td>4.573€</td>
              </tr>
              <tr>
                <td>A partir de 75.001€</td>
                <td>
                  5% mais pouvant aller jusqu'à 10% (du montant de la vente)
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Concernant la vente</th>
                <th>Rénumération</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Terrain</td>
                <td>15%</td>
              </tr>
              <tr>
                <td>Parking</td>
                <td>
                  30%
                </td>
              </tr>
            </tbody>
          </Table>
          <div style={{ textAlign: "start", marginTop: 15, width: "100%" }}>
            <span>
              Charge de la rémunération conformement au mandat de vente soit
              HONORAIRES CHARGES ACQUEREUR.
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 25,
              }}
            >
              <span style={{ fontWeight: "bold"}}>HONORAIRES CHARGE ACQUEREUR</span>
              <span style={{ fontWeight: "bold" }}>ESTIMATION :</span>
              <span>
                Les estimations écrites sont facturées 150€ TTC sauf en cas de
                mise en vente.
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>COMPROMIS DE VENTE :</span>
              <span>
                La rédaction d'un compromis de vente, établi par l'agence, sera
                facturée 305€ TTC soit 254.17€ HT.
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>LOCATION AGENCE HOUDAN - MAULE:</span>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Frais à la charge du locataire :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      Visite, constitution du dossier et rédaction du bail
                    </td>
                    <td>8€ TTC par m²</td>
                  </tr>
                  <tr>
                    <td>Etats des lieux</td>
                    <td>3€ TTC par m²</td>
                  </tr>
                </tbody>
              </Table>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Frais à la charge du bailleur :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      Visite, constitution du dossier et rédaction du bail
                    </td>
                    <td>8€ TTC par m²</td>
                  </tr>
                  <tr>
                    <td>Etats des lieux</td>
                    <td>3€ TTC par m²</td>
                  </tr>
                </tbody>
              </Table>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Gstion locative :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      5% HT 
                    </td>
                    <td>6% TTC</td>
                  </tr>
                  <tr>
                    <td>Garantie loyer impayé 2.5%</td>
                    <td>3% TTC</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>
              <span style={{ fontWeight: "bold" }}>LOCATION AGENCE PARIS:</span>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Frais à la charge du locataire :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      Visite, constitution du dossier et rédaction du bail
                    </td>
                    <td>12€ TTC par m²</td>
                  </tr>
                  <tr>
                    <td>Etats des lieux</td>
                    <td>3€ TTC par m²</td>
                  </tr>
                </tbody>
              </Table>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Frais à la charge du bailleur :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      Visite, constitution du dossier et rédaction du bail
                    </td>
                    <td>12€ TTC par m²</td>
                  </tr>
                  <tr>
                    <td>Etats des lieux</td>
                    <td>3€ TTC par m²</td>
                  </tr>
                </tbody>
              </Table>
              <span style={{ fontWeight: "bold", marginTop: 10}}>
                Gstion locative :
              </span>
              <Table bordered style={{ marginTop: 2 }}>
                <tbody>
                  <tr>
                    <td>
                      5% HT 
                    </td>
                    <td>6% TTC</td>
                  </tr>
                  <tr>
                    <td>Garantie loyer impayé 2.5%</td>
                    <td>3% TTC</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
      <Footer />
    </motion.div>
  );
}

var BackgroundImage = {
  display: "flex",
  flexDirection: "column",
  height: "auto",
  backgroundImage: `url(${photo})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  maxWidth: "100%",
  padding: 0,
};

var descRow = {
  width: "60%",
  display: "flex",
  flexDirection: "column",
  justifySelf: "center",
  alignSelf: "center",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "calc(1em + 5vw)",
  marginBottom: "calc(1em + 5vw)",
  backgroundColor: "rgba(255,255,255, 0.9)",
  borderRadius: 10,
  padding: 25,
};

var col1 = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

var titleStyle = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  padding: "30px 20px 0px 20px",
  color: "#206A37",
  fontSize: "calc(1em + 2vw)",
  textAlign: "center",
  fontWeight: "bold",
};

var subTitleStyle = {
  fontSize: 12,
  color: "#206A37",
  fontWeight: "bold",
};

var span1Style = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  padding: "2vh",
  textAlign: "justify",
  fontSize: "calc(0.4em + 0.5vw)",
};

var span2Style = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  padding: "1vh",
  textAlign: "justify",
  fontSize: "calc(0.4em + 0.5vw)",
};

var span3Style = {
  display: "flex",
  justifySelf: "center",
  alignSelf: "center",
  paddingRight: "2vh",
  paddingLeft: "2vh",
  paddingBottom: "2vh",
  textAlign: "justify",
  fontSize: "calc(0.4em + 0.5vw)",
};

export default Honoraires;
