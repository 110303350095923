import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Popover, Input, PopoverBody, Label, Button, ButtonGroup  } from 'reactstrap';
import photo from './images/rechercheAvance.png'
import logo from './images/logo.png'
import { Link } from 'react-router-dom';
import user from './images/user.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Divider, InputNumber, Tag  } from 'antd';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencilRuler} from '@fortawesome/free-solid-svg-icons'
import Appartement from './images/AppIcon.png'
import House from './images/maisonIcon.png'
import Loft from './images/loft.png'
import Hotel from './images/hotelIcon.png'
import Bureau from './images/oficceIcon.png'
import Parking from './images/garageIcon.png'
import Immeuble from './images/ImmeubleIcon.png'
import Terrain from './images/terrainIcon.png'
import Footer from './Footer'
import { motion } from 'framer-motion'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import NavBar from "./NavBar"







function RechercheAvance(props) {

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')

  const [logInMessage, setLogInMessage] = useState([])

  const [logInAccepted, setLogInAccepted] = useState(false)

  const [userGenre, setUserGenre] = useState('')
  const [usersName, setUsersName] = useState('')

  const [userFoundFromToken, setUserFoundFromToken] = useState(localStorage.getItem('usersToken'))

  const [clickApp, setClickApp] = useState(false)
  const [clickMaison, setClickMaison] = useState(false)
  const [clickLoft, setClickLoft] = useState(false)
  const [clickGrange, setClickGrange] = useState(false)
  const [clickParking, setClickParking] = useState(false)
  const [clickImmeuble, setClickImmeuble] = useState(false)
  const [clickTerrain, setClickTerrain] = useState(false)
  const [clickFondsDeCommerce, setClickFondsDeCommerce] = useState(false)
  const [clickLocalCommercial, setLocalCommercial] = useState(false)

  const [budgetMin, setBudgetMin] = useState()
  const [budgetMax, setBudgetMax] = useState()
  const [surfaceMin, setSurfaceMin] = useState()
  const [surfaceMax, setSurfaceMax] = useState()
  const [terrainMin, setTerrainMin] = useState()
  const [terrainMax, setTerrainMax] = useState()

  const [piecesStudio, setPiecesStudio] = useState(false)
  const [pieces2, setPieces2] = useState(false)
  const [pieces3, setPieces3] = useState(false)
  const [pieces4, setPieces4] = useState(false)
  const [pieces5, setPieces5] = useState(false)

  const [chambres1, setChambres1] = useState(false)
  const [chambres2, setChambres2] = useState(false)
  const [chambres3, setChambres3] = useState(false)
  const [chambres4, setChambres4] = useState(false)
  const [chambres5, setChambres5] = useState(false)









  const [placeAuto, setPlaceAuto] = useState("")
  const [citiesTableau, setCitiesTableau] = useState([])



  function onChangeBudgetMin(value) {
    setBudgetMin(value)
  }
  function onChangeBudgetMax(value) {
    setBudgetMax(value)
  }
  function onChangeSurfaceMin(value) {
    setSurfaceMin(value)
  }
  function onChangeSurfaceMax(value) {
    setSurfaceMax(value)
  }
  function onChangeTerrainMin(value) {
    setTerrainMin(value)
  }
  function onChangeTerrainMax(value) {
    setTerrainMax(value)
  }


  
      
  useEffect(async() => {


      if(userFoundFromToken != null){

          
      var rawData = await fetch('/sendToken', {
          method: 'POST',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: `tokenFromFront=${userFoundFromToken}`
    }); 
    
        var data = await rawData.json()
        
        if(data.result === true){
          setLogInAccepted(true)
          setUsersName(data.user.prenom)
          setUserGenre(data.user.genre)
        }

      }


       
    },[]);





  var styleOfApp = typeStyleNotSelected;
  var styleOfMaison = typeStyleNotSelected;
  var styleOfLoft = typeStyleNotSelected;
  var styleOfGrange = typeStyleNotSelected;
  var styleOfParking = typeStyleNotSelected;
  var styleOfImmeuble = typeStyleNotSelected;
  var styleOfFonds = typeStyleNotSelected;
  var styleOfTerrain = typeStyleNotSelected;
  var styleOfLocalCommercial = typeStyleNotSelected

  var stylePiecesStudio = typeStyleNotSelected
  var stylePieces2 = typeStyleNotSelected
  var stylePieces3 = typeStyleNotSelected
  var stylePieces4 = typeStyleNotSelected
  var stylePieces5 = typeStyleNotSelected

  var styleChambres1 = typeStyleNotSelected
  var styleChambres2 = typeStyleNotSelected
  var styleChambres3 = typeStyleNotSelected
  var styleChambres4 = typeStyleNotSelected
  var styleChambres5 = typeStyleNotSelected

  // var styleAchatAncien = typeStyleNotSelected
  // var styleAchatNeuf = typeStyleNotSelected
  // var styleAchatProjet = typeStyleNotSelected

  if(clickApp === true){
    styleOfApp = typeStyleSelected
  }
  if(clickMaison === true){
    styleOfMaison = typeStyleSelected
  }
  if(clickLoft === true){
    styleOfLoft = typeStyleSelected
  }
  if(clickGrange === true){
    styleOfGrange = typeStyleSelected
  }
  if(clickParking === true){
    styleOfParking = typeStyleSelected
  }
  if(clickImmeuble === true){
    styleOfImmeuble = typeStyleSelected
  }
  if(clickFondsDeCommerce === true){
    styleOfFonds = typeStyleSelected
  }
  if(clickTerrain === true){
    styleOfTerrain = typeStyleSelected
  }
  if(clickLocalCommercial === true){
    styleOfLocalCommercial = typeStyleSelected
  }

  if(piecesStudio === true){
    stylePiecesStudio = typeStyleSelected
  }
  if(pieces2 === true){
    stylePieces2 = typeStyleSelected
  }
  if(pieces3 === true){
    stylePieces3 = typeStyleSelected
  }
  if(pieces4 === true){
    stylePieces4 = typeStyleSelected
  }
  if(pieces5 === true){
    stylePieces5 = typeStyleSelected
  }


  if(chambres1 === true){
    styleChambres1 = typeStyleSelected
  }
  if(chambres2 === true){
    styleChambres2 = typeStyleSelected
  }
  if(chambres3 === true){
    styleChambres3 = typeStyleSelected
  }
  if(chambres4 === true){
    styleChambres4 = typeStyleSelected
  }
  if(chambres5 === true){
    styleChambres5 = typeStyleSelected
  }

  useEffect(async() => {

    var actualCity = ""

    if(placeAuto.value != undefined){
      actualCity = placeAuto.value.structured_formatting.secondary_text
    }

    var rawData = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${placeAuto.label}&result_type=postal_code&key=AIzaSyBfHwT25FD-yBY7aShuI9OfqLKc_QWHzFs`); 
    var data = await rawData.json()
    var bounds = data.results[0].geometry.location

    var getPostalCode = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${bounds.lat},${bounds.lng}&result_type=postal_code&key=AIzaSyBfHwT25FD-yBY7aShuI9OfqLKc_QWHzFs`); 
    var postalCodeData = await getPostalCode.json()
    var codePostal = postalCodeData.results[0].address_components[0].long_name
    if(placeAuto.label === "Paris, France"){
      codePostal = "75"
    }

    setCitiesTableau( [...citiesTableau, {city: placeAuto.label, codePostal: codePostal, bounds: {lat: bounds.lat, lng: bounds.lng}, ville: actualCity } ])


    },[placeAuto]);

  function handleValider(){

    var noBiensSelected = false;

    if(clickApp === false &&
      clickMaison === false &&
      clickLoft === false &&
      clickImmeuble === false &&
      clickParking === false &&
      clickFondsDeCommerce === false &&
      clickTerrain === false &&
      clickGrange === false &&
      clickFondsDeCommerce === false){
        noBiensSelected = true
      }

      var combienPieces = []
      if(piecesStudio === true){
        combienPieces.push("Studio")
      }
      if(pieces2 === true){
        combienPieces.push(2)
      }

    var filters = {
      citiesTableau: citiesTableau,
      citiesMap: props.citiesFromMapsToDisplay,
      typeDeTransaction: props.typeTransactionToDisplay,
      budgetMin: budgetMin,
      budgetMax: budgetMax,
      appartement: clickApp,
      maison: clickMaison,
      loft: clickLoft,
      immeuble: clickImmeuble,
      parking: clickParking,
      fondCommerce: clickFondsDeCommerce,
      terrain: clickTerrain,
      grange: clickGrange,
      localCommercial: clickFondsDeCommerce,
      noBiensSelected: noBiensSelected,
      surfaceMin: surfaceMin,
      surfaceMax: surfaceMax,
      terrainMin: terrainMin,
      terrainMax: terrainMax
    }

  }



  return (
<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{opacity: 0 }}
>
      <Container style={BackgroundImage}>

        <NavBar/>


        <Row style={firstRow}>

        
          <Row>
            <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
              <span style={spanContactezNous}>Type de bien:</span>
            </Col>
          </Row>
          
          <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />


          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            <span style={styleOfApp} onClick={()=>setClickApp(!clickApp)}>
              Appartement
            </span>
            <span style={styleOfMaison} onClick={()=>setClickMaison(!clickMaison)}>
              Maison
            </span>
            <span style={styleOfLoft} onClick={()=>setClickLoft(!clickLoft)}>
              Loft
            </span>
            <span style={styleOfParking} onClick={()=>setClickParking(!clickParking)}>
              Parking / Box
            </span>
            <span style={styleOfImmeuble} onClick={()=>setClickImmeuble(!clickImmeuble)}>
              Immeuble
            </span>
            <span style={styleOfGrange} onClick={()=>setClickGrange(!clickGrange)}>
              Grange
            </span>
            <span style={styleOfTerrain} onClick={()=>setClickTerrain(!clickTerrain)}>
              Terrain
            </span>
            <span style={styleOfFonds} onClick={()=>setClickFondsDeCommerce(!clickFondsDeCommerce)}>
              Fonds de Commerce
            </span>
            <span style={styleOfLocalCommercial} onClick={()=>setLocalCommercial(!clickLocalCommercial)}>
              Local Commercial            
            </span>
          </Row>

        </Row>

        <Row style={firstRow}>

          <Row>
            <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
              <span style={spanContactezNous}>A quelle endroit ?</span>
            </Col>
          </Row>
          
          <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            
            <Col xs='12' lg='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
            <Row style={{marginBottom: 10, marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center"}}>
                {
                  citiesTableau.map(function(city, i){
                    if(city.city != undefined){
                      return(
                        <Tag style={{marginBottom: 5}} key={i} color="green" closable onClose={()=>setCitiesTableau(citiesTableau.filter((e)=>(e.city != city.city)))} >{city.city}</Tag>
                      )
                    }
                  })
                }
              </Row>
              <GooglePlacesAutocomplete
                selectProps={{
                  placeAuto,
                  onChange: setPlaceAuto,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      color: '#206A37',
                      width: "200px",
                    }),
                  },
                }}
                apiKey="AIzaSyDFL0VzZ5rQB4FxgH_UWVlZXLBkkun_LSc" 
                apiOptions={{ language: 'fr', region: 'fr' }}
              />
            </Col>
            <Col xs='12' lg='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
              <Button size='sm' style={{backgroundColor:'#206A37', color:'white', width: "200px"}}><FontAwesomeIcon icon={faPencilRuler} style={{color: 'white', margin: '2px'}}/>En dessinant sur la map</Button>
            </Col>
            
          </Row>

          
        </Row>


        <Row style={firstRow}>

          <Row>
            <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
              <span style={spanContactezNous}>Pour quelle budget ?</span>
            </Col>
          </Row>
          
          <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            
            <Col xs='12' lg='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
              <InputNumber
                size="middle"
                placeholder='Min'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={budgetMin}
                onChange={onChangeBudgetMin}
                style={{width: "250px"}}
              />
            </Col>
            <Col xs='12' lg='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
              <InputNumber
                size="middle"
                placeholder='Max'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={budgetMax}
                onChange={onChangeBudgetMax}
                style={{width: "250px"}}
              />
            </Col>
            
          </Row>

          
        </Row>

        <Row style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center',width: '60%'}}>

          <Col xs='12' lg='6' style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',backgroundColor: 'rgba(255,255,255, 0.9)',justifySelf: 'center',alignSelf: 'center',marginTop: 'calc(1em + 1vw)',borderRadius: '10px', padding: 0}}>

            <Row>
              <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={spanContactezNous}>Quelle surface habitable?</span>
              </Col>
            </Row>
            
            <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <InputNumber
                size="middle"
                placeholder='Min'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={surfaceMin}
                onChange={onChangeSurfaceMin}
                style={{width: "150px"}}
                />
              </Col>
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <InputNumber
                size="middle"
                placeholder='Max'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={surfaceMax}
                onChange={onChangeSurfaceMax}
                style={{width: "150px"}}
              />
              </Col>
            
            </Row>  

          </Col>

          <Col xs='12' lg='6' style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',backgroundColor: 'rgba(255,255,255, 0.9)',justifySelf: 'center',alignSelf: 'center',marginTop: 'calc(1em + 1vw)',borderRadius: '10px', padding: 0}}>

            <Row>
              <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={spanContactezNous}>Surface du terrain ?</span>
              </Col>
            </Row>
            
            <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <InputNumber
                size="middle"
                placeholder='Min'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={terrainMin}
                onChange={onChangeTerrainMin}
                style={{width: "150px"}}
                />
              </Col>
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <InputNumber
                size="middle"
                placeholder='Max'
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={terrainMax}
                onChange={onChangeTerrainMax}
                style={{width: "150px"}}
              />
              </Col>
            
            </Row>

          </Col>

        </Row>

        
        <Row style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',alignItems: 'center', width: '60%'}}>

          <Col xs='12' lg='6' style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',backgroundColor: 'rgba(255,255,255, 0.9)',justifySelf: 'center',alignSelf: 'center',marginTop: 'calc(1em + 1vw)',borderRadius: '10px', padding: 0}}>

            <Row>
              <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={spanContactezNous}>Combien de piéces ?</span>
              </Col>
            </Row>
            
            <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            <span style={stylePiecesStudio} onClick={()=>setPiecesStudio(!piecesStudio)}>
              Studio
            </span>
            <span style={stylePieces2} onClick={()=>setPieces2(!pieces2)}>
              2
            </span>
            <span style={stylePieces3} onClick={()=>setPieces3(!pieces3)}>
              3
            </span>
            <span style={stylePieces4} onClick={()=>setPieces4(!pieces4)}>
              4
            </span>
            <span style={stylePieces5} onClick={()=>setPieces5(!pieces5)}>
              5
            </span>
            </Row> 

          </Col>

          <Col xs='12' lg='6' style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',backgroundColor: 'rgba(255,255,255, 0.9)',width: '60%',justifySelf: 'center',alignSelf: 'center',marginTop: 'calc(1em + 1vw)',borderRadius: '10px', padding: 0}}>

            <Row>
              <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={spanContactezNous}>Combien de chambres ?</span>
              </Col>
            </Row>
            
            <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            <span style={styleChambres1} onClick={()=>setChambres1(!chambres1)}>
              Studio
            </span>
            <span style={styleChambres2} onClick={()=>setChambres2(!chambres2)}>
              2
            </span>
            <span style={styleChambres3} onClick={()=>setChambres3(!chambres3)}>
              3
            </span>
            <span style={styleChambres4} onClick={()=>setChambres4(!chambres4)}>
              4
            </span>
            <span style={styleChambres5} onClick={()=>setChambres5(!chambres5)}>
              5
            </span>
            </Row> 

          </Col>

        </Row>

        {/* <Row style={firstRow}>

          <Row>
            <Col xs='12' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
              <span style={spanContactezNous}>Type d’achats :</span>
            </Col>
          </Row>
          
          <Divider style={{width: '100%', backgroundColor: '#206A37', height: '0.7px', margin: 0}} />

            <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            <span style={styleChambres1} onClick={()=>setChambres1(!chambres1)}>
              Ancien
            </span>
            <span style={styleChambres2} onClick={()=>setChambres2(!chambres2)}>
              Neuf
            </span>
            <span style={styleChambres3} onClick={()=>setChambres3(!chambres3)}>
              Projet en construction
            </span>
            </Row> 

          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%', marginTop: '5px', marginBottom: '5px'}}>
            
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <Label check style={radioButtonsStyle}>
                  <Input type="checkbox" name="Appartement" />
                  Neuf
                </Label>
              </Col>
              <Col xs='6' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', justifySelf: 'center', alignSelf: 'center', width: '100%'}}>
                <Label check style={radioButtonsStyle}>
                  <Input type="checkbox" name="Appartement" />
                  Ancien
                </Label>
              </Col>
            
          </Row>

          
        </Row> */}

        <Row style={lastRow} onClick={handleValider()}>
          <Link to='/resultats' type='button' style={{color: '#206A37', fontSize: 'calc(1em + 0.6vw)', padding: '5px'}}>Valider</Link>
        </Row>

      </Container>
      <Footer/>
</motion.div>
  );
}

function mapStateToProps(state) {
  return {
    typeTransactionToDisplay: state.typeTransaction,
    citiesFromMapsToDisplay: state.mapCities,
  }
}

var BackgroundImage = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    height:'auto',
    backgroundImage: `url(${photo})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    maxWidth: '100%',
    padding: 0
}

var typeStyleNotSelected = {
  marginRight: 2,
  marginLeft: 2,
  border: "1px dashed #206A37",
  borderRadius: 10,
  padding: 7,
  cursor: "pointer",
  color: "#206A37"
}
var typeStyleSelected = {
  marginRight: 2,
  marginLeft: 2,
  backgroundColor: "#206A37",
  color: "white",
  borderRadius: 10,
  padding: 7,
  cursor: "pointer",
  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
}

var firstRow = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255)',
  width: '60%',
  justifySelf: 'center',
  alignSelf: 'center',
  marginTop: 'calc(1em + 1vw)',
  borderRadius: '10px',
  border: 0
}

var lastRow = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255, 0.8)',
  width: '60%',
  justifySelf: 'center',
  alignSelf: 'center',
  marginTop: 'calc(1em + 1vw)',
  borderRadius: '10px',
  border: 0,
  marginBottom: 'calc(1em + 1vw)'
}


 var spanContactezNous = {
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  color: '#206A37',
  fontSize: 'calc(1em + 0.6vw)',
  marginBottom: '5px',
  textAlign: 'center'
}

var iconsStyle = {
  margin: '5px', 
  border: '2px solid #206A37', 
  borderRadius: 100, 
  padding: 10, 
  width: '70px'
}

var radioButtonsStyle = {
  textAlign: 'center',
  color: '#206A37'
  
}



export default connect(
  mapStateToProps,
)(RechercheAvance);