import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col  } from 'reactstrap';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import Footer from './Footer'
import { motion } from 'framer-motion'
import { connect } from 'react-redux';
import VenteApp from './BienToShow/acheter/VenteApp';
import VenteMaison from './BienToShow/acheter/VenteMaison';
import VenteTerrain from './BienToShow/acheter/VenteTerrain';
import VenteFondsCommerce from './BienToShow/acheter/VenteFontCommerce';
import VenteGrange from './BienToShow/acheter/VenteGrange';
import VenteLoft from './BienToShow/acheter/VenteLoft';
import VenteImmeuble from './BienToShow/acheter/VenteImmeuble';
import VenteParking from './BienToShow/acheter/VenteParking';
import VenteLocalCommercial from './BienToShow/acheter/VenteLocalCommercial'
import LocationApp from './BienToShow/louer/LocationApp';
import LocationMaison from './BienToShow/louer/LocationMaison';
import LocationImmeuble from './BienToShow/louer/LocationImmeuble';
import LocationLoft from './BienToShow/louer/LocationLoft';
import LocationParking from './BienToShow/louer/LocationParking';
import LocationLocalCommercial from './BienToShow/louer/LocationLocalCommercial';
import ViagerApp from "./BienToShow/viager/ViagerApp"
import NavBar from "./NavBar"
import { InputNumber } from "antd";







function Resultats(props) {


  const [bienList, setBienList] = useState([])
  const [trierPar, setTrierPar] = useState("Trier par")
  const [budgetMin, setBudgetMin] = useState(localStorage.getItem("Budget Min"));
  const [budgetMax, setBudgetMax] = useState(localStorage.getItem("Budget Max"));


  const [plusDeType, setPlusDeType] = useState(false)

  const [radioTypeDeBien, setRadioTypeDeBien] = useState({maison: true, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
  const [radioAgence, setRadioAgence] = useState({paris: false, maule: false, houdan: false})

  function onChangeBudgetMin(value) {
    setBudgetMin(value);
  }

  function onChangeBudgetMax(value) {
    setBudgetMax(value);
  }


  
  useEffect(async() => {  


    const typeDeBienFromLS = localStorage.getItem('Type de Bien');
    const transactionFromLS = localStorage.getItem('transaction');

    if(localStorage.getItem('Agency') == "Paris"){
      setRadioAgence({paris: true, maule: false, houdan: false})
    }
    if(localStorage.getItem('Agency') == "Houdan"){
      setRadioAgence({paris: false, maule: false, houdan: true})
    }
    if(localStorage.getItem('Agency') == "Maule"){
      setRadioAgence({paris: false, maule: true, houdan: false})
    }


    if(transactionFromLS === "Acheter"){
      let venteArray = [];
      if(typeDeBienFromLS === "Maison et Appartement"){
        venteArray.push(<VenteMaison/>, <VenteApp/>)
        setRadioTypeDeBien({maison: true, appartement: true, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Appartement"){
        venteArray.push(<VenteApp/>)
        setRadioTypeDeBien({maison: false, appartement: true, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Maison"){
        venteArray.push(<VenteMaison/>)
        setRadioTypeDeBien({maison: true, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Terrain"){
        venteArray.push(<VenteTerrain/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: true, localCommerce: false})
      }
      if(typeDeBienFromLS === "Fond de Commerce"){
        venteArray.push(<VenteFondsCommerce/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: true, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Grange"){
        venteArray.push(<VenteGrange/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: true, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Loft"){
        venteArray.push(<VenteLoft/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: true, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Parking"){
        venteArray.push(<VenteParking/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: true, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Immeuble"){
        venteArray.push(<VenteImmeuble/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: true, fondCommerce: false, terrain: false, localCommerce: false})
      }
      if(typeDeBienFromLS === "Local Commercial"){
        venteArray.push(<VenteLocalCommercial/>)
        setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: true})
      }
      setBienList(venteArray)
    }

      if(transactionFromLS === "Louer"){
        let locationArray = [];
        if(typeDeBienFromLS === "Appartement"){
          locationArray.push(<LocationApp/>)
          setRadioTypeDeBien({maison: false, appartement: true, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
        }
        if(typeDeBienFromLS === "Immeuble"){
          locationArray.push(<LocationImmeuble/>)
          setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: true, fondCommerce: false, terrain: false, localCommerce: false})
        }
        if(typeDeBienFromLS === "Loft"){
          locationArray.push(<LocationLoft/>)
          setRadioTypeDeBien({maison: false, appartement: false, loft: true, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
        }
        if(typeDeBienFromLS === "Maison"){
          locationArray.push(<LocationMaison/>)
          setRadioTypeDeBien({maison: true, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
        }
        if(typeDeBienFromLS === "Parking"){
          locationArray.push(<LocationParking/>)
          setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: true, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})
        }
        if(typeDeBienFromLS === "Local Commercial"){
          locationArray.push(<LocationLocalCommercial/>)
          setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: true})
        }
        setBienList(locationArray)
      }

      if(props.filtresDeRechercheToDisplay.typeDeTransaction === "Viager"){
        let viagerArray = [];
        if(props.filtresDeRechercheToDisplay.appartement === true){
          viagerArray.push(<ViagerApp/>)
        }
        setBienList(viagerArray)

      }

      console.log("bienList :", bienList)
      

    
       
    },[]);

  function handleMenuClick(e) {
    setTrierPar(e.key)
    props.onTrierPar(e.key)
  }


  
  function onClickValider(){

    localStorage.setItem("Budget Min", budgetMin)
    localStorage.setItem("Budget Max", budgetMax)

    window.location.reload()
    
  }



  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Prix croissant">
        Prix croissant
      </Menu.Item>
      <Menu.Item key="Prix décroissant">
        Prix décroissant
      </Menu.Item>
    </Menu>
  );

  var typeStyleNotSelected = {
    marginRight: 2,
    marginLeft: 2,
    border: "1px dashed #206A37",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    color: "#206A37",
  };
  var typeStyleSelected = {
    marginRight: 2,
    marginLeft: 2,
    backgroundColor: "#206A37",
    color: "white",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
  };

  var agencyStyleNotSelected = {
    width: 120,
    marginRight: 2,
    marginLeft: 2,
    border: "1px dashed #206A37",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    color: "#206A37",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",

  };

  var agencyStyleSelected = {
    width: 120,
    marginRight: 2,
    marginLeft: 2,
    backgroundColor: "#206A37",
    color: "white",
    borderRadius: 10,
    padding: 7,
    cursor: "pointer",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };

  var styleOfApp = typeStyleNotSelected;
  var styleOfMaison = typeStyleSelected;
  var styleOfLoft = typeStyleNotSelected;
  var styleOfGrange = typeStyleNotSelected;
  var styleOfParking = typeStyleNotSelected;
  var styleOfImmeuble = typeStyleNotSelected;
  var styleOfFonds = typeStyleNotSelected;
  var styleOfTerrain = typeStyleNotSelected;
  var styleOfLocalCommercial = typeStyleNotSelected;

  var styleOfParis = agencyStyleNotSelected
  var styleOfHoudan = agencyStyleNotSelected
  var styleOfMaule = agencyStyleNotSelected

  function onClickMaison(){
    localStorage.setItem('Type de Bien', "Maison");
    setRadioTypeDeBien({maison: true, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})

  }
  function onClickAppartement(){
    localStorage.setItem('Type de Bien', "Appartement");
    setRadioTypeDeBien({maison: false, appartement: true, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})


  }
  function onClickLoft(){
    localStorage.setItem('Type de Bien', "Loft");
    setRadioTypeDeBien({maison: false, appartement: false, loft: true, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})

  }
  function onClickGrange(){
    localStorage.setItem('Type de Bien', "Grange");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: true, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})

  }
  function onClickParking(){
    localStorage.setItem('Type de Bien', "Parking");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: true, immeuble: false, fondCommerce: false, terrain: false, localCommerce: false})

  }
  function onClickImmeuble(){
    localStorage.setItem('Type de Bien', "Immeuble");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: true, fondCommerce: false, terrain: false, localCommerce: false})

  }
  function onClickFonds(){
    localStorage.setItem('Type de Bien', "Fond de Commerce");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: true, terrain: false, localCommerce: false})

  }
  function onClickTerrain(){
    localStorage.setItem('Type de Bien', "Terrain");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: true, localCommerce: false})

  }
  function onClickLocalCommercial(){
    localStorage.setItem('Type de Bien', "Local Commercial");
    setRadioTypeDeBien({maison: false, appartement: false, loft: false, grange: false, parking: false, immeuble: false, fondCommerce: false, terrain: false, localCommerce: true})

  }

  if(radioAgence.paris === true){
    styleOfParis = agencyStyleSelected
  }

  if(radioAgence.houdan === true){
    styleOfHoudan = agencyStyleSelected
  }

  if(radioAgence.maule === true){
    styleOfMaule = agencyStyleSelected
  }

  if (radioTypeDeBien.appartement === true) {
    styleOfApp = typeStyleSelected;
  }
  if (radioTypeDeBien.maison === false) {
    styleOfMaison = typeStyleNotSelected;
  }
  if (radioTypeDeBien.loft === true) {
    styleOfLoft = typeStyleSelected;
  }
  if (radioTypeDeBien.grange === true) {
    styleOfGrange = typeStyleSelected;
  }
  if (radioTypeDeBien.parking === true) {
    styleOfParking = typeStyleSelected;
  }
  if (radioTypeDeBien.immeuble === true) {
    styleOfImmeuble = typeStyleSelected;
  }
  if (radioTypeDeBien.fondCommerce === true) {
    styleOfFonds = typeStyleSelected;
  }
  if (radioTypeDeBien.terrain === true) {
    styleOfTerrain = typeStyleSelected;
  }
  if (radioTypeDeBien.localCommerce === true) {
    styleOfLocalCommercial = typeStyleSelected;
  }


  let inputTerrain = (
    <span style={styleOfTerrain} onClick={() => onClickTerrain()}>
      Terrain
    </span>
  );

  let inputGrange = (
    <span style={styleOfGrange} onClick={() => onClickGrange()}>
      Grange
    </span>
  );

  let inputFondsDeCommerce = (
    <span
      style={styleOfFonds}
      onClick={() => onClickFonds()}
    >
      Fonds de Commerce
    </span>
  );

  var basicInputs = (
    <span>
      <span style={styleOfMaison} onClick={() => onClickMaison()}>
        Maison
      </span>
      <span style={styleOfApp} onClick={() => onClickAppartement()}>
        Appartement
      </span>
      <Button style={{backgroundColor: "#206A37", borderRadius: "5px", color: "white"}} onClick={()=>setPlusDeType(!plusDeType)}>+</Button>
    </span>
  );

  var allInputs = (
    <span
      style={{
        width: "100%",
        marginBottom: 30,
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Row
        style={{
          diplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <span style={styleOfMaison} onClick={() => onClickMaison()}>
          Maison
        </span>
        <span style={styleOfApp} onClick={() => onClickAppartement()}>
          Appartement
        </span>
        {inputTerrain}
        <span>
          <Button style={{backgroundColor: "#206A37", borderRadius: "5px", color: "white"}} onClick={()=>setPlusDeType(!plusDeType)}>-</Button>
        </span>
        
      </Row>

      <Row
        style={{
          diplay: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: 5,
        }}
      >
        <span
          style={styleOfLocalCommercial}
          onClick={() =>  onClickLocalCommercial()}
        >
          Local Commercial
        </span>
        <span style={styleOfLoft} onClick={() => onClickLoft()}>
          Loft
        </span>
        <span style={styleOfParking} onClick={() =>  onClickParking()}>
          Parking / Box
        </span>
        <span style={styleOfImmeuble} onClick={() =>  onClickImmeuble()}>
          Immeuble
        </span>
        {inputFondsDeCommerce}
        {inputGrange}
      </Row>
    </span>
  );


function onClickParis(){
  setRadioAgence({paris: true, maule: false, houdan: false})
  localStorage.setItem("Agency", "Paris")
}
function onClickMaule(){
  setRadioAgence({paris: false, maule: true, houdan: false})
  localStorage.setItem("Agency", "Maule")
}
function onClickHoudan(){
  setRadioAgence({paris: false, maule: false, houdan: true})
  localStorage.setItem("Agency", "Houdan")
}

  return (

<motion.div
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  exit={{opacity: 0 }}
>

      <Container className='test' style={BackgroundImage}>

      <NavBar/>

      <Row style={{width: "100%", marginBottom: 30, display: "flex",marginLeft: 5}}>
        <Col style={{display: "flex", alignItems: "flex-start", }}>
          <Link to="/">
            <Button style={{color: "#206A37", backgroundColor: "white"}}>
              <BiArrowBack style={{marginRight: 5}}/>
              Retour 
            </Button>
          </Link>
        </Col>
        <Col style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
          <Dropdown overlay={menu} style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
            <Button style={{color: "#206A37"}}>
              {trierPar} 
              <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
        </Row>
        <Row style={{width: "100%", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 10, justifySelf: "center", alignSelf: "center"}}>
          <Col xs="10" md="3" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {plusDeType?(allInputs):(basicInputs)}
          </Col>
          <Col xs="10" md="4" style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <div>
              <span style={{ color: "#206A37", textAlign: "center", fontWeight: "bold"}}>Quelle agence ?</span>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15, marginBottom: 25}}>
              <span
                style={styleOfParis}
                onClick={() => onClickParis()}>
                <span style={{fontSize: 17,fontWeight: "bold"}}>PARIS</span>
                <span style={{fontWeight: "bold"}}>( 75 )</span>
              </span>
              <span
                style={styleOfHoudan}
                onClick={() => onClickHoudan()}>
                <span style={{fontSize: 17,fontWeight: "bold"}}>HOUDAN</span>
                <span style={{fontWeight: "bold"}}>( 78-28-27 )</span>
              </span>
              <span
                style={styleOfMaule}
                onClick={() => onClickMaule()}>
                <span style={{fontSize: 17,fontWeight: "bold"}}>MAULE</span>
                <span style={{fontWeight: "bold"}}>( 78 )</span>
              </span>
            </div>
          </Col>
          <Col xs="10" md="3" style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <span style={{ color: "#206A37", textAlign: "center", fontWeight: "bold" }}>
                  Votre budget Min?
                </span>
                <InputNumber
                  size="middle"
                  defaultValue={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  value={budgetMin}
                  onChange={onChangeBudgetMin}
                  style={{ width: "150px", border: "solid 0.5px #206A37" }}
                />
            </Row>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <span style={{ color: "#206A37", textAlign: "center" , fontWeight: "bold"}}>
                  Votre budget Max?
                </span>
                <InputNumber
                  size="middle"
                  defaultValue={1000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  value={budgetMax}
                  onChange={onChangeBudgetMax}
                  style={{ width: "150px", border: "solid 0.5px #206A37" }}
                />
            </Row>
          </Col>
          <Col xs="10" md="2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Button onClick={()=>onClickValider()} style={{backgroundColor: "#206A37", color: "white", borderRadius: "5px", padding: "5px"}}>Rafraichir</Button>
          </Col>
        </Row>
        <Col xs="12" style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"}}>
          {bienList}
        </Col>
      </Container>
      <Footer/>

</motion.div>
  );
}

function mapDispatchToProps(dispatch){
  return {
    onTrierPar: function(trier){
      dispatch( {type: "addTrier", thisTrier: trier})
    },
    onFiltersValidate: function (filters) {
      dispatch({ type: "addFilters", thisFilters: filters });
    },
  }
}


function mapStateToProps(state) {
  return {
    typeTransactionToDisplay: state.typeTransaction,
    filtresDeRechercheToDisplay: state.filtresDeRecherche,
    biensLengthToDisplay: state.biensLength,
  }
}


var BackgroundImage = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height:'auto',
  backgroundImage: "linear-gradient(to right top, #569e67, #86b57a, #b1cb92, #d9e3ae, #fffbce)",
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  maxWidth: '100%',
  minWidth: "100%",
  padding: 0,
  margin: 0,
}



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resultats)
